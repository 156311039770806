import * as Yup from 'yup';
import {CommonValidators} from './CommonValidators';

export class ClientValidator {
    public static GetSchema(shouldValidateDealership: any): Yup.AnySchema {
        let schema = Yup.object().shape({
            company: Yup.object().shape({
                name: CommonValidators.commonFieldValidator
            }),
        });
        if (shouldValidateDealership.dealership.id !== -1) {
            schema = schema.shape({
                dealership: Yup.object().shape({
                    name: CommonValidators.commonFieldValidator,
                    email: CommonValidators.emailValidator,
                    phone: CommonValidators.phoneValidator,
                }),
                copyShippingToBilling: Yup.boolean(),
                billingAddress: Yup.object().when('copyShippingToBilling', {
                    is: false,
                    then: Yup.object().shape({
                        state: Yup.string().nullable().notRequired(),
                        city: Yup.string().nullable().notRequired(),
                        street: Yup.string().nullable().notRequired(),
                        zip: Yup.string().nullable().notRequired(),
                    }),
                }),
                shippingAddress: Yup.object().shape({
                    state: Yup.string().nullable().notRequired(),
                    city: Yup.string().nullable().notRequired(),
                    street: Yup.string().nullable().notRequired(),
                    zip: Yup.string().nullable().notRequired(),
                }),
            });
        }
        return schema;
    }
}
