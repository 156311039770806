import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {Box, Flex, Text, Progress} from "@chakra-ui/react";
import {useAppSelector} from "../../redux/Store";
import {ProductController} from "../../controllers/ProductController";
import {Product, ProductClass} from "../../shared/entities/Product/Product";
import {GalleryListButton} from "../../components/Buttons/GalleryListButton";
import {ProductTypes} from "../../common/constants";
import {ArchiveItem} from "../../components/Inputs/Icons/Icons";
import {BoxRowStyleConfig, BoxStyleConfig} from "../Forms/FormStyleConfigs";
import {URLPaths} from "../../config/application/URLPaths";
import {addedBreadcrumbsLinks, setBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {Icon} from "@chakra-ui/icons";
import {Paginator} from "../../components/Paignator/Paginator";
import CommonSection from "./MainPage/CommonSection";
import ProductCard from "./ProductCard";
import {FaArrowRight} from "react-icons/all";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {MainButton} from "../../components/Buttons/MainButton";
import {Helmet} from "react-helmet";
import SchemaOrg from "../../components/SchemaOrg/SchemaOrg";
import {WebPUtils} from "../../shared/utilities/WebPUtils";
import ProductPage404 from "../404/ProductPage404";
import CustomLink from "../../components/Link/CustomLink";
import AllowedAccess, {hasPermission} from "../../features/Permission/AllowedAccess";
import {productCreatePermission} from "../permissions";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
export const Products: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    // const searchItems = useAppSelector((state) => state.NavBarReducer.searchItems);
    const changeDragAccept = useAppSelector((state) => state.DragAndDropReducer.changeDragAccept);
    const user = useAppSelector(store => store.AuthReducer.user);
    const productController = new ProductController(dispatch);
    const {urlItem} = useParams();
    const id = urlItem?.split("-")[0];
    const location = useLocation();
    const containsArchive = location.pathname.includes("archive");
    const [, setShouldRender] = useState(false);
    const [currentPaginatorPosition, setCurrentPaginatorPosition] = useState(0)
    const [previewPaginatorPosition, setPreviewPaginatorPosition] = useState(0)
    const [size, setSize] = useState(50)
    const [limitCount, setLimitCount] = useState(0)
    const isHomePage = (location.pathname === URLPaths.HOME.link || location.pathname === URLPaths.HOME_ARCHIVE.link);
    const query = useQuery().get('s');
    const isGeneralPage = (isHomePage && (query === null || query === ""))
    const [updatedProductList, setProductList] = useState<Array<Product>>([]);
    const [parentProduct, setParentProduct] = useState<Product>();
    const isShowCart = parentProduct && parentProduct.showCart && !query;

    const isProductGroup = parentProduct?.type === ProductTypes.OPTIONS || parentProduct?.type === ProductTypes.CATEGORY || parentProduct?.type === ProductTypes.SUBCATEGORY;

    function capitalizeWords(text: string) {
        return text
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const tuneProducts = (productList: Array<Product>) => {
        const updatedProductList: Array<Product> = [];
        for (const elem of productList) {
            updatedProductList.push({...elem});
        }

        const parentProduct = updatedProductList?.find((elem: Product) => elem.id === Number(id));
        const filteredChildProducts = updatedProductList?.filter((elem: Product) => elem.id !== Number(id))
        addedBreadcrumbsLinks(dispatch, !containsArchive ? {
                link: location.pathname,
                text: capitalizeWords(parentProduct?.name || "")
            }
            : {link: location.pathname, text: "Archive"});
        if (filteredChildProducts.length === 0 && parentProduct) {
            filteredChildProducts.push(parentProduct);
        }

        if (hasPermission(productCreatePermission)) {
            filteredChildProducts.push(new ProductClass(isGeneralPage ? ProductTypes.CATEGORY : ProductTypes.PRODUCT));
        }

        const filteredProducts = containsArchive
            ? filteredChildProducts?.filter((elem: Product) => elem.archive)
            : filteredChildProducts?.filter((elem: Product) => !elem.archive);

        setProductList(hasPermission(productCreatePermission) ? filteredProducts : filteredProducts?.filter((v) => !v.hiddenProduct));

        setParentProduct(parentProduct);
        setBreadcrumbsLinks(dispatch, WebPUtils.generateBreadcrumbs(parentProduct));
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        const reqConfig = {
            params: {
                // page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : 0,
                page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : null,
                size: size,
                // name: isHomePage ? "" : urlItem!.replace(/^[^-]*-/, "").toLowerCase().trim().replace(/_/g, " "),
                url: isHomePage ? "" : urlItem,
                id: isHomePage ? 0 : Number(id),
                search_text: "",
                archive: (containsArchive),
            }
        }

        if (query) {
            reqConfig.params["search_text"] = query;
        }
        if (isGeneralPage) {
            reqConfig.params.page = null;
        }
        productController.getProducts(reqConfig.params).then(productList => {
            tuneProducts(productList.Some.items === null ? [] : productList.Some.items);
            setLimitCount(productList.Some.count === null ? 0 : productList.Some.count);
        });

        if ((currentPaginatorPosition === previewPaginatorPosition)) {
            setCurrentPaginatorPosition(0);
        }
        setPreviewPaginatorPosition(currentPaginatorPosition);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPaginatorPosition, id, query, containsArchive, changeDragAccept, size, user]);


    const handlePageChange = (newPage: number) => {
        setPreviewPaginatorPosition(currentPaginatorPosition)
        setCurrentPaginatorPosition(newPage)
    }

    const getDescription = (text?: string): string => {
        if (!text) return "";
        return (text.length > 250 ? text.slice(0, 250) + "..." : text).replace(/\s*\n\s*/g, ' ').trim();
    };
    const description = parentProduct?.type === ProductTypes.PRODUCT
        ? getDescription(parentProduct.descriptionCart)
        : getDescription(parentProduct?.description);

    return (
        <>
            {updatedProductList &&
                <>
                    <Helmet>
                        <title>
                            {isGeneralPage
                                ? "Home Page Title — AK Dealer Services"
                                : `${parentProduct?.name || ""}`}
                        </title>
                        <meta
                            name="description"
                            content={
                                isGeneralPage
                                    ? "AK Dealer Services provides quality auto parts and services for auto dealers. Explore our range of products and solutions."
                                    : (description || parentProduct?.name)
                            }
                        />
                        <link rel="canonical" href={window.location.origin + window.location.pathname}/>
                    </Helmet>
                    <SchemaOrg
                        isGeneralPage={isGeneralPage}
                        isProductGroup={isProductGroup}
                        product={parentProduct || new ProductClass()}
                        url={window.location.origin + window.location.pathname}
                        description={description}
                    />
                </>
            }
            <Box>
                {isLoading &&
                    <Progress size='xs' isIndeterminate/>
                }
                {isShowCart && !isLoading && (
                    <ProductCard parentProduct={parentProduct}/>
                )}
                {updatedProductList && updatedProductList.length === 0 && !isLoading && (
                    <ProductPage404/>
                )}
                {updatedProductList && updatedProductList.length > 0 && !isLoading &&
                    <Box {...BoxStyleConfig}>
                        {!isGeneralPage && (!isShowCart || containsArchive) &&
                            <Box pb={3}>
                                <Breadcrumbs/>
                            </Box>
                        }
                        <Box>
                            {isGeneralPage &&
                                <>
                                    <CommonSection productList={updatedProductList}
                                                   groupTypes={ProductTypes.SUBCATEGORY}/>
                                    <CommonSection productList={updatedProductList}
                                                   groupTypes={ProductTypes.CATEGORY}/>
                                    <CommonSection productList={updatedProductList}
                                                   groupTypes={ProductTypes.ANOTHER}/>
                                    {!isLoading &&

                                        <CustomLink href={`${URLPaths.ORDER_BY_CATALOG.link}`}>
                                            <Flex justifyContent={"center"} pt={'72px'}>
                                                <MainButton height={"80px"} width={"302px"} fontSize={16}
                                                            lineHeight={'120%'}> <Box>
                                                    <Text>{'Need more products?'}</Text>
                                                    <Text>{'Order from catalog'}</Text>
                                                </Box>
                                                </MainButton>
                                            </Flex>
                                        </CustomLink>
                                    }
                                </>
                            }
                            {(isShowCart && parentProduct.type !== ProductTypes.OPTIONS) &&
                                <Text pb={5} fontSize={22}>Same products</Text>
                            }

                            <CommonSection productList={updatedProductList}
                                           groupTypes={ProductTypes.PRODUCT}
                                           someCondition={isShowCart && parentProduct.type !== ProductTypes.OPTIONS}/>

                            {(isShowCart && parentProduct.type !== ProductTypes.OPTIONS) &&
                                <Flex justifyContent={"center"}>
                                    <CustomLink
                                        href={WebPUtils.getProductBreadLink(parentProduct.breadcrumbs[parentProduct.breadcrumbs.length - 1])}>
                                        <Flex>
                                            <Text fontWeight={"md"}>{`See all ${parentProduct.parentName}`}</Text>
                                            <Icon as={FaArrowRight} px={2} fontSize={28} color={"gray.800"}
                                                  cursor={"pointer"}/>
                                        </Flex>
                                        <Box border={"2px"} borderColor={"gray.300"} width={"100%"}/>
                                    </CustomLink>
                                </Flex>
                            }
                        </Box>
                        <AllowedAccess permissionKey={productCreatePermission} localPermission={!isLoading}>
                            <Box pt={1}>
                                <GalleryListButton w={"140px"} textDecoration="underline" height={"38"}
                                                   fontSize="16px"
                                                   leftIcon={<ArchiveItem fontSize="large"/>}
                                                   onClick={() => {
                                                       setShouldRender(prevState => !prevState);
                                                       if (id !== undefined) {
                                                           const redirectPath = containsArchive
                                                               ? `${URLPaths.PRODUCTS.link}${urlItem}`
                                                               : `${URLPaths.PRODUCTS_ARCHIVE.link}${urlItem}`;
                                                           navigate(redirectPath)
                                                       } else {
                                                           const redirectPath = containsArchive
                                                               ? `${URLPaths.HOME.link}`
                                                               : `${URLPaths.HOME_ARCHIVE.link}`;
                                                           navigate(redirectPath)
                                                       }
                                                   }}>
                                    <Flex alignItems="flex-end">{containsArchive ? "Back" : "Archive"}</Flex>
                                </GalleryListButton>
                            </Box>
                        </AllowedAccess>

                        {!isGeneralPage && !isLoading && !(isShowCart && parentProduct.type !== ProductTypes.OPTIONS) && (updatedProductList.length >= 50 || currentPaginatorPosition > 0) &&
                            <Box {...BoxRowStyleConfig} mt="auto">
                                <Paginator limit={limitCount} step={size} current={currentPaginatorPosition}
                                           swapPageTrigger={handlePageChange}/>
                                {/*<Select*/}
                                {/*    defaultValue={size}*/}
                                {/*    onChange={(event) => setSize(Number(event.target.value))}*/}
                                {/*    maxWidth="80px"*/}
                                {/*    ml="10"*/}
                                {/*>*/}
                                {/*    {[50, 100].map((option) => (*/}
                                {/*        <option key={option} value={option}>*/}
                                {/*            {option}*/}
                                {/*        </option>*/}
                                {/*    ))}*/}
                                {/*</Select>*/}
                            </Box>
                        }
                    </Box>
                }
            </Box>
        </>
    );
};
export default Products;