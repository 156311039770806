import {Option} from "../../shared/utilities/OptionT";
import {CustomError} from "../../shared/errors/CustomErrors";
import {APIDao, Requester} from "../../repository/APIRequester";
import {ORDER_API_PREFIX, PRODUCT_API_PREFIX, SUBSCRIBE_API_PREFIX} from "../../common/constants";

class Downloder {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async getOrderListXls(params: any): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${ORDER_API_PREFIX}/xls`, {
            ...params,
            responseType: "blob"
        }).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async getProductPrice(params: any): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${PRODUCT_API_PREFIX}/price-xls`, {
            ...params,
            responseType: "blob"
        }).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }
    public async getSubscribeListXls(): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${SUBSCRIBE_API_PREFIX}/emails-xls`, {responseType: "blob"}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }
}

export const DownloaderInstance = new Downloder(APIDao);