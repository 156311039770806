import {Box} from "@chakra-ui/react";
import {InfoRow} from "./InfoRow";
import {useAppSelector} from "../../redux/Store";
import {FC, useEffect, useState} from "react";
import {Dealership} from "../../shared/entities/Users/Dealership";
import {Address} from "../../shared/entities/Users/User";
import {headingFabric, HeadingType} from "../../components/Headings/Heading";
import {InfoBox} from "./InfoBox";


export const PersonalInformation: FC = () => {
    const user = useAppSelector(store => store.AuthReducer.user);
    const dealership = useAppSelector(state => state.AuthReducer.dealership)
    const [selectedDealership, setSelectedDealership] = useState<Dealership | null>(null);

    useEffect(() => {
        if (user) {
            let dealer = user.dealerships.find(value => value.name === sessionStorage.getItem("DEALERSHIP"))
            if (dealer !== null && dealer !== undefined) {
                setSelectedDealership(dealer)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, dealership])

    function createStringAddress(address: Address | undefined): string {
        if (address) {
            return `${address.street}, ${address.city}, ${address.state?.name}, ${address.zip}`
        }
        return "no Address"
    }

    return (
        <Box pl={{base: 0, lg: 10}} w="100%">
            <Box >
                <Box pb={4}>
                    {headingFabric.Generate({
                        type: HeadingType.FORM_HEADING,
                        text: "Personal Information"
                    })}
                </Box>
                <InfoRow
                    leftLabel="Company Name "
                    leftContent={selectedDealership?.companyName || ''}
                    rightLabel="Dealership Name "
                    rightContent={selectedDealership?.name || ''}
                />
                <InfoRow
                    leftLabel="User Name "
                    leftContent={user?.login || ''}
                    rightLabel="Dealership e-mail "
                    rightContent={selectedDealership?.email?.split(',') || []}
                />
                <InfoRow
                    leftLabel="User E-mail"
                    leftContent={user?.email?.split(',') || []}
                    rightLabel="Dealership phone "
                    rightContent={selectedDealership?.phone || ''}
                />
            </Box>
            <Box >
                <InfoBox label={"Shipping Address "}
                         content={createStringAddress(selectedDealership?.shippingAddress)}/>
            </Box>
        </Box>
    )
};