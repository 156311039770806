import React from 'react';
import {Product} from '../../../shared/entities/Product/Product';
import {ProductTypes} from "../../../common/constants";
import {Box, Grid, GridItem, useBreakpointValue} from "@chakra-ui/react";
import {ProductListManager} from "../../addaptive/ProductListManager";
import {generalBox} from "../../../theme/foundations/breakpoints";
import NewProductComponent from "../NewProductComponent";
import ProductGeneralItem from "./ProductGeneralItem";
import CategoryGeneralItem from "./CategoryGeneralItem";
import AnotherGeneralItem from "./AnotherGeneralItem";
import ProductItem from "../ProductItem";
import NeedMoreProduct from "./NeedMoreProduct";
import {hasPermission} from "../../../features/Permission/AllowedAccess";
import {productEditPermission} from "../../permissions";

interface ProductSectionProps<T> {
    productList: Array<Product>;
    groupTypes: ProductTypes;
    someCondition?: boolean;
}

const CommonSection = <T extends {}>({productList, groupTypes, someCondition = false}: ProductSectionProps<T>) => {
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false});

    let selGroup = [groupTypes];
    let elemInfo = generalBox.subcategory
    switch (groupTypes) {
        case ProductTypes.SUBCATEGORY:
            elemInfo = generalBox.subcategory
            break
        case ProductTypes.CATEGORY:
            elemInfo = generalBox.category
            break
        case ProductTypes.ADDITIONAL:
            elemInfo = generalBox.additional
            break
        case ProductTypes.PRODUCT:
        case ProductTypes.OPTIONS:
        case ProductTypes.REORDER:
        case ProductTypes.CUSTOM:
            selGroup = [ProductTypes.PRODUCT, ProductTypes.OPTIONS, ProductTypes.CUSTOM, ProductTypes.REORDER, ProductTypes.NEED_MORE_PRODUCTS]
            elemInfo = generalBox.product
            break
        case ProductTypes.ANOTHER:
            elemInfo = generalBox.another
            break
        default:
            elemInfo = generalBox.subcategory
            break
    }
    let rows = ProductListManager.getRowsProducts(productList, groupTypes === ProductTypes.ANOTHER ? 2 : 4, selGroup);
    if (someCondition) {
        rows = rows.slice(0, 1);
    }
    return (
        <Box justifyContent={"space-around"}>
            {rows.map((rowProducts, rowIndex) => (
                <Grid
                    key={rowIndex}
                    gap={isMobile ? 3 : hasPermission(productEditPermission) ? 0 : 6}
                    paddingTop={elemInfo.pT} paddingBottom={elemInfo.pB}
                    gridTemplateColumns={{
                        base: `repeat(${groupTypes === ProductTypes.PRODUCT ? 2 : 1}, 1fr)`,
                        sm: `repeat(${groupTypes === ProductTypes.PRODUCT ? 2 : 1}, 1fr)`,
                        md: `repeat(${groupTypes === ProductTypes.ANOTHER ? 1 : 2}, 1fr)`,
                        lg: `repeat(${groupTypes === ProductTypes.ANOTHER && rowProducts.length > 1 ? 2 : 4}, 1fr)`
                    }}
                >
                    {rowProducts.map((product, idx) => (
                        <GridItem key={idx}
                                  colStart={(groupTypes === ProductTypes.ANOTHER && rowProducts.length === 1 && !isMobile) ? 2 : undefined}
                                  colEnd={(groupTypes === ProductTypes.ANOTHER && rowProducts.length === 1 && !isMobile) ? 4 : undefined}
                        >
                            {product && product.id === 0 ? (
                                <NewProductComponent product={product} height={`${elemInfo.height}px`}/>) : (
                                <>
                                    {(groupTypes === ProductTypes.SUBCATEGORY &&
                                        <ProductGeneralItem product={product} isMobile={isMobile}/>
                                    )}
                                    {(groupTypes === ProductTypes.CATEGORY &&
                                        <CategoryGeneralItem product={product} isMobile={isMobile}/>
                                    )}
                                    {(groupTypes === ProductTypes.ANOTHER &&
                                        <AnotherGeneralItem product={product} isMobile={isMobile}/>
                                    )}
                                    {((groupTypes === ProductTypes.PRODUCT && product.type !== ProductTypes.NEED_MORE_PRODUCTS) &&
                                        <ProductItem key={idx} product={product} isMobile={isMobile}/>
                                    )}
                                    {(groupTypes === ProductTypes.PRODUCT && product.type === ProductTypes.NEED_MORE_PRODUCTS &&
                                        <NeedMoreProduct key={idx} product={product} height={`${elemInfo.width}px`}
                                                         isMobile={isMobile}/>
                                    )}
                                </>
                            )}
                        </GridItem>
                    ))}
                </Grid>
            ))}
        </Box>
    );
};

export default CommonSection;