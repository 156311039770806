import React, {FC, useEffect, useState} from 'react';
import {Box, Select, Text} from "@chakra-ui/react";
import {Discount, Product} from "../../../shared/entities/Product/Product";
import {ListProductItemConfig, ListProductItemRow} from "../../../components/List/ListProductItem";
import DiscountComponent from "./DiscountComponent";
import {ProductCostCalculateUtils} from "../../../shared/utilities/ProductCostCalculateUtils";
import {useAppSelector} from "../../../redux/Store";
import {UserController} from "../../../controllers/UserController";
import {useDispatch} from "react-redux";
import {Dealership} from "../../../shared/entities/Users/Dealership";
import {ProductTypes} from "../../../common/constants";


const ShowPriceCost: FC<{ contentDiscount: Array<Discount> }> = ({contentDiscount}) => {
    const dispatch = useDispatch()

    const [product, setProduct] = useState<Product>();
    const [selectedDealer, setSelectedDealer] = useState<Dealership | undefined>();
    const [updateDealer, setUpdateDealer] = useState<boolean>(false);

    const authDealership = useAppSelector((state) => state.AuthReducer.dealership);
    const item = useAppSelector((state) => state.ProductReducer.productGet);
    const dealershipsMinInfo = useAppSelector(state => state.UserReducer.dealershipsMinInfo);
    const userController = new UserController(dispatch)
    const selDealership = useAppSelector(state => state.UserReducer.dealership);

    const handleSelectChange = (event: any) => {
        if (event.target.value) {
            setUpdateDealer(true);
            userController.getDealership({id: event.target.value});
        }
    }

    useEffect(() => {
        if (item) {
            setProduct(item);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);


    useEffect(() => {
        if (authDealership) {
            setSelectedDealer(updateDealer ? selDealership : authDealership)
        }
    }, [authDealership, selDealership]);

    interface ColumnConfig {
        header: string;
        width?: string;
        isLink?: boolean;
        style?: object;
    }

    const columns: Array<ColumnConfig> = [
        {header: "Price", width: "20%"},
        {header: "Quantity", width: "20%"},
        {header: "Total price", width: "20%"},
        {header: "Ship", width: "15%"},
        {header: "Local Ship", width: "20%"},
        {header: "Tax", width: "5%"},
    ];

    const ColumnNamesMapping: Array<ListProductItemConfig> = columns.map(
        (col: ColumnConfig) => {
            return {content: col.header || "", width: col.width || "auto"};
        }
    );

    const distinctByField = (array: Discount[], field: keyof Discount): Discount[] => {
        const uniqueValues = new Set<any>();

        return array.filter(item => {
            const value = item[field];
            if (uniqueValues.has(value)) {
                return false;
            }

            uniqueValues.add(value);
            return true;
        });
    };

    const getList = () => {
        if (product) {
            const qntPart = [1, product.shippingMinSize + 1];
            if (product.startValue > product.shippingMinSize) {
                qntPart.push(product.startValue);
            }

            return qntPart.map((quantity: number) => {
                product.quantity = quantity
                const ItemConfig: Array<ListProductItemConfig> = [
                    {
                        content: <DiscountComponent elem={product} hiddenUOM={true} history={false} textAlign={"left"}
                                                    selectedDealer={selectedDealer} showMarkup={true} showTax={true} showDiscount={true}/>,
                    },
                    {
                        content: <Text fontSize="14px">{quantity}</Text>,
                    },
                    {
                        content: <Text
                            fontSize="14px"> {"$" + Number((ProductCostCalculateUtils.calculatePrice(product, selectedDealer) * product.quantity).toFixed(2))}</Text>
                    },
                    {
                        content: <Text
                            fontSize="14px"> {(ProductCostCalculateUtils.calculateShippingCost(product, selectedDealer)).toFixed(2) + "$"}</Text>,
                    },
                    {
                        content: <Text
                            fontSize="14px"> {(ProductCostCalculateUtils.calculateShippingCost(product, selectedDealer,undefined,true)).toFixed(2) + "$"}</Text>,
                    },
                    {
                        content: <Text
                            fontSize="14px"> {(ProductCostCalculateUtils.calculateTaxCost(product, selectedDealer)).toFixed(2) + "$"}</Text>,
                    }
                ]
                return <ListProductItemRow key={quantity} list={ItemConfig} noBorder={false}/>;
            });
        }
    }


    return (
        <Box pt={2}>
            <ListProductItemRow list={ColumnNamesMapping} style={{
                fontSize: "14px",
                minHeight: "35px",
                justifyContent: "flex-start"
            }}/>
            {getList()}
            {(item && item.type === ProductTypes.PRODUCT) &&
                <Select onChange={handleSelectChange} height="2em" defaultValue={0} mt={"0.5rem"}>
                    {[...dealershipsMinInfo.filter(dealership =>
                        distinctByField(contentDiscount, 'dealershipId').some(item => item.dealershipId === dealership.id)
                    ), {
                        id: 0,
                        name: "show price for dealership"
                    }].map((elem: any) => (
                        <option key={elem.id} value={elem.id} hidden={elem.id === 0}>
                            {elem.name}
                        </option>
                    ))}
                </Select>
            }
        </Box>
    );
}
export default ShowPriceCost;