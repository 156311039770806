import {Box, ChakraProps, Icon, Tooltip} from "@chakra-ui/react";
import React from "react";


interface IconWithTooltipProps extends ChakraProps {
    icon?: any;
    tooltipMessage?: string;
    onClick?: () => void;
    isOutlined?: boolean;
    isHoverOutlined?: boolean;
    isDisabled?: boolean;
    color?: string;
    fontSize?: string;
    label?: string;
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({
                                                             icon,
                                                             tooltipMessage,
                                                             onClick,
                                                             fontSize = "1rem",
                                                             isOutlined = false,
                                                             isHoverOutlined = true,
                                                             isDisabled = false,
                                                             color,
                                                             label,
                                                             ...props
                                                         }) => {

    return (
        <Tooltip fontSize={14} hasArrow label={tooltipMessage} bg="gray.300" color="black">
            <Box as={"button"}
                 type="button"
                 h={6}
                 ml={"0.2rem"}
                 _hover={{
                     borderRadius: "10%",
                     border: isHoverOutlined ? "0.2px solid gray" : "none",
                 }}
                 border={isOutlined ? "1px solid red" : "none"}
                 borderRadius="10%"
                 display="inline-block"
                 onClick={onClick}
                 opacity={isDisabled ? 0.5 : 1}
                 cursor={isDisabled ? "not-allowed" : "pointer"}
                 pointerEvents={isDisabled ? "none" : "auto"}
                 color={color} // Устанавливаем цвет иконки
                 {...props}
            >
                {label ?
                    <Box p={"1px"}>{label}</Box>
                    :
                    <Icon
                        as={icon}
                        strokeWidth={1}
                        fontSize={fontSize}
                        cursor={"pointer"}
                        color={color}
                    />
                }
            </Box>
        </Tooltip>
    );
};

export default IconWithTooltip;
