import React from "react";
import {Box, Checkbox, Text} from "@chakra-ui/react";
import ImageCustom from "../../components/Image/ImageCustom";
import {PriceProduct} from "../../shared/entities/Product/Product";
import {ListProductItemConfig, ListProductItemRow} from "../../components/List/ListProductItem";
import {useNavigate} from "react-router";

interface ProductVendorRow {
    index: number;
    style: React.CSSProperties;
    elem: PriceProduct;
    selectedItems: any[];
    onToggle: (dealership: any, remove: boolean) => void;
}

export const ProductVendorRow: React.FC<ProductVendorRow> = ({index, style, elem, selectedItems, onToggle}) => {
    // const navigate = useNavigate();

    if (!elem) return null;

    const isSelected = selectedItems.some((item) => item === elem.product.id);

    const ItemConfig: Array<ListProductItemConfig> = [
        {
            content: <ImageCustom elem={elem.product} sizeInPercent={100} currentSize={"small"}
                                  showOnHover={false}/>,
            isLink: false,
            width: "10%",
        },
        {
            content: <Text>{elem.product.name}</Text>,
            isLink: false,
            width: "85%",
        },
        // {
        //     content: <Box>
        //         {elem.orderIds.map((id: any) =>
        //             <Text textDecoration={"underline"} cursor={"pointer"}
        //                   _hover={{color: "gray.800", textDecoration: "unset"}}
        //                   onClick={() => navigate(`${URLPaths.ORDER_VIEW.link}${id}`)}>{id}</Text>
        //         )} </Box>
        //     ,
        //     isLink: false,
        //     width: "20%",
        // },

        {
            content: <Checkbox font={14} isChecked={isSelected} onChange={() => onToggle(elem.product, isSelected)}/>,
            isLink: false,
            width: "5%",
        },
    ];

    return (
        <Box style={style} key={index}>
            <ListProductItemRow list={ItemConfig} style={{justifyContent: "flex-start", fontSize: 14}}/>
        </Box>
    );
};