import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    ChakraProps,
    Flex,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader, PopoverTrigger, PopoverArrow,
    Text,
} from "@chakra-ui/react";
import {Product} from "../../shared/entities/Product/Product";
import {useAppSelector} from "../../redux/Store";
import {URLPaths} from "../../config/application/URLPaths";
import {useNavigate} from "react-router";
import ImageCustom from "../../components/Image/ImageCustom";
import DiscountComponent from "../Products/Discount/DiscountComponent";
import {Order} from "../../shared/entities/Order/Order";
import {OrderProduct} from "../../shared/entities/OrderProduct/OrderProduct";
import {OrderProductController} from "../../controllers/OrderProductController";
import {useDispatch} from "react-redux";
import {Loader} from "../../components/Loader/Loader";
import AllowedAccess, {hasPermission} from "../../features/Permission/AllowedAccess";
import {orderEditPermission} from "../permissions";

interface CartModalProps {
    order: Order;
}

const ShowOrderModal: React.FC<CartModalProps> = ({order}) => {
    const [productList, setProductList] = useState<Array<Product>>([]);
    const [countProductOrder, setCountProductOrder] = useState<number>(0);
    const [showAllItems, setShowAllItems] = useState(false);
    const navigate = useNavigate();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [shippingCost, setShippingCost] = useState<number>(0);
    const [tax, setTax] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);

    const [orderProductList, setOrderProductList] = useState<Array<OrderProduct>>([]);

    const dispatch = useDispatch();
    const orderProductController = new OrderProductController(dispatch);
    const lineStyle: ChakraProps = {
        borderBottom: "1px solid #B3B2B2",
        width: "100%",
        mr: "225px",
        mt: "5px",
        mb: "5px",
    };
    const authDealership = useAppSelector((state) => state.AuthReducer.dealership);


    const handleLinkClick = () => {
        // setIsMenuOpen(false);
        navigate(`${URLPaths.ORDER_VIEW.link}${order?.id}`)
    };

    const onOpen = () => {
        if (order) {
            setIsLoading(true);
            orderProductController.getOrderProducts(Number(order.id)).then(orderProductList => {
                setOrderProductList(orderProductList.Some);
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        if (orderProductList && order) {
            setTax(order.tax)
            setTotalPrice(order.totalAmount)
            setShippingCost(order.shippingCost)
            // orderProductList.forEach((orderProduct) => {
            const newUpdatedProductList: Array<Product> = [];
            for (const elem of orderProductList) {
                elem.product.quantity = elem.quantity
                elem.product.discount = elem.discount
                elem.product.shippingCost = elem.shippingCost
                elem.product.price = elem.price
                elem.product.finalPrice = elem.finalPrice
                newUpdatedProductList.push({...elem.product});
            }

            const updatedProductList: Array<Product> = [];
            for (const elem of newUpdatedProductList) {
                if (updatedProductList.length >= 3 && !showAllItems) {
                    break;
                }
                updatedProductList.push({...elem});
            }
            setProductList(updatedProductList);
            setCountProductOrder(newUpdatedProductList.length);
        }
    }, [orderProductList, showAllItems]);

    return (
        <Popover trigger="hover" openDelay={200} closeDelay={200} placement='right' onOpen={onOpen}>
            <PopoverTrigger>
                <Text textDecoration={"underline"} _hover={{textDecoration: "unset", cursor: "pointer"}}
                      fontWeight={{base: "md", lg: "sm"}}
                      onClick={handleLinkClick}
                >{order?.id}</Text>
            </PopoverTrigger>
            <PopoverContent
                style={{
                    border: "1.5px solid #6B7280",
                    boxShadow: "0px 4px 32px #B3B2B2",
                    maxHeight: "calc(67vh - 20px)",
                    minWidth: "400px",
                }}
            >
                <PopoverArrow/>
                {isLoading
                    ? <Loader/>
                    : <>
                        <PopoverHeader display="flex" fontSize="14px" textTransform="none">
                            <Text onClick={handleLinkClick} position="relative" pl={1} cursor={"pointer"}
                                  _hover={{textDecoration: "underline"}}>
                                {`${countProductOrder} Items in invoice`}
                            </Text>
                        </PopoverHeader>
                        <PopoverBody>
                            {countProductOrder === 0 ? (
                                <Text>Cart empty</Text>
                            ) : (
                                <Box>
                                    {productList.map((product) => (
                                        <React.Fragment key={product.id}>
                                            <Flex
                                                key={product.id}
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                {/* Image */}
                                                <ImageCustom elem={product} sizeInPercent={100} currentSize={"small"}
                                                             showOnHover={false}/>
                                                <Text
                                                    ml={"5px"}
                                                    fontSize="14px"
                                                    style={{
                                                        width: "33%",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: "vertical",
                                                        wordWrap: "break-word",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {product.name}
                                                </Text>
                                                <Text>
                                                    {product.quantity}
                                                </Text>
                                                <Box ml={"5px"}/>
                                                <DiscountComponent
                                                    elem={product}
                                                    hiddenUOM={true}
                                                    history={true}
                                                    showDiscount={true}
                                                    showMarkup={hasPermission(orderEditPermission)}
                                                    selectedDealer={authDealership ? authDealership : undefined}
                                                />
                                            </Flex>
                                            <Box {...lineStyle}></Box>
                                        </React.Fragment>
                                    ))}
                                    <Flex flexDirection="column">
                                        <Flex alignItems="center" justifyContent="space-between">
                                            {countProductOrder > 3 && !showAllItems && (
                                                <Flex justifyContent="center">
                                                    <Button
                                                        variant="link"
                                                        fontSize="14px"
                                                        onClick={() => setShowAllItems(true)}
                                                    >
                                                        +{countProductOrder - 3} More Items
                                                    </Button>
                                                </Flex>
                                            )}
                                            <AllowedAccess permissionKey={orderEditPermission}>
                                                <Flex flexDirection="column" ml={"auto"}>
                                                    <Text fontWeight="bold" fontSize="14px" ml={"auto"}>
                                                        Shipping: ${shippingCost.toFixed(2)}
                                                    </Text>
                                                    <Text fontWeight="bold" fontSize="14px" ml={"auto"}>
                                                        Tax: ${tax.toFixed(2)}
                                                    </Text>
                                                    <Text fontWeight="bold" fontSize="14px" ml={"auto"}>
                                                        Total: ${totalPrice.toFixed(2)}
                                                    </Text>
                                                </Flex>
                                            </AllowedAccess>
                                        </Flex>
                                    </Flex>
                                </Box>
                            )}
                        </PopoverBody>
                    </>
                }
            </PopoverContent>
        </Popover>
    );
};

export default ShowOrderModal;
