import {Flex, Spinner} from "@chakra-ui/react";
import React, {FC, useState} from "react";
import {useDispatch} from "react-redux";
import {ModalService} from "../../service/Modal/ModalService";
import {Option} from "../../shared/utilities/OptionT";
import IconWithTooltip from "../Icon/IconWithTooltip";
import NavBarLinkButton from "../Link/NavBarLinkButton";

var fileDownload = require('js-file-download');

/**
 * Кнопка скачивания документов в реестрах
 *
 * Принимает функцию, которая должна вызываться по названию
 * @param param0
 * @returns
 */
export const DownloadButton: FC<{
    clickAction: Function,
    height?: string,
    nameFile: string,
    icon?: any,
    label?: string
}> = ({
          clickAction,
          height,
          icon,
          label = 'Download File',
          nameFile
      }) => {
    const dispatch = useDispatch();
    const modalService = new ModalService(dispatch);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true); // Начинаем загрузку
        try {
            const res: Option<any> = await clickAction();
            if (res?.None) {
                modalService.setModalData({
                    onSubmit: () => modalService.deleteModalData(),
                    isOpen: true,
                    onOpen: () => {
                    },
                    onClose: () => modalService.deleteModalData(),
                    modalTextData: "Error upload File",
                    context: "error",
                });
            } else {
                fileDownload(res.Some, nameFile);
            }
        } catch (error) {
            modalService.setModalData({
                onSubmit: () => modalService.deleteModalData(),
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => modalService.deleteModalData(),
                modalTextData: "Error during file download",
                context: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Flex
            py={icon ? 2 : 0}
            align="center"
            cursor={isLoading ? "not-allowed" : "pointer"}
            onClick={!isLoading ? handleClick : undefined} // Блокируем клик во время загрузки
        >
            {isLoading ? (
                <Spinner size="sm"/>
            ) : icon ? (
                <IconWithTooltip
                    icon={icon}
                    tooltipMessage={label}
                    fontSize="22px"
                />
            ) : (
                <NavBarLinkButton pl={1} fontSize={14} fontWeight={"sm"} text={label}/>
            )}
        </Flex>
    );
};