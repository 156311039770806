import {Form, Formik} from "formik";
import React, {FC, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {AuthorizationController} from "../../../controllers/AuthController";
import {AuthValidator} from "../../../controllers/Validation/AuthValidator";
import {authErrorHandler} from "../../../hooks/AuthHook";
import {MainButton} from "../../../components/Buttons/MainButton";
import {Box, Flex, InputGroup, InputRightElement, Text} from "@chakra-ui/react";
import NavBarLinkButton from "../../../components/Link/NavBarLinkButton";
import {URLPaths} from "../../../config/application/URLPaths";
import {BoxStyleConfig} from "../FormStyleConfigs";
import {useAppSelector} from "../../../redux/Store";
import {ViewOffIcon} from "@chakra-ui/icons";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {setBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";

/**
 * Форма авторизации
 * @returns
 */
interface AuthorizationFormProps {
    links?: Array<BreadcrumbsData>,
    basket?: boolean;
}

export const AuthorizationForm: FC<AuthorizationFormProps> = ({links, basket = false}) => {
    const dispatch = useDispatch()
    const pathWithoutAuth = useLocation().pathname.replace("/auth", "");
    const navigate = useNavigate()
    const loginForCode = useAppSelector(state => state.UserReducer.loginForCode);
    const authorizationControllerInstance = new AuthorizationController(dispatch)
    const publicKey = useAppSelector((state) => state.AuthReducer.publicKey);
    const [showOldPassword, setShowOldPassword] = useState(false);

    if (links) {
        setBreadcrumbsLinks(dispatch, links)
    }
    const AuthorizationFormCardConfig = {
        marginX: "auto",
        paddingBottom: {base: 20, lg: "unset"},
        w: {
            sm: "100%",
            md: "100%",
            lg: basket ? "110%" : "50%",
            xl: basket ? "150%" : "35%"
        },
        h: {
            sm: "50%",
            lg: "50%"
        },
        // position: "relative",
        justifyContent: "center"
    }


    return (
        <Box>
            {links && (
                <Breadcrumbs/>
            )}
            <Box {...AuthorizationFormCardConfig}>
                <Text fontSize={30} fontWeight={"md"} w={"90%"}
                      display={{base: basket ? "block" : "none", lg: "block"}}>Registered
                    customers</Text>
                {basket && (
                    <Box py={4}>
                        <Text fontWeight={"sm"} w={"90%"}>Please log in and checkout</Text>
                    </Box>
                )}
                <Formik initialValues={{LOGIN: loginForCode, PASSWORD: ""}}
                        onSubmit={async (values, actions) => {
                            actions.setSubmitting(true)
                            try {
                                await authorizationControllerInstance.authorizeFunc(values, publicKey);
                                actions.setSubmitting(false);
                                authErrorHandler.handleAuthChange(true);

                                navigate(pathWithoutAuth ? pathWithoutAuth : URLPaths.HOME.link);
                            } catch (error) {
                                actions.setFieldError("LOGIN", " ");
                                actions.setFieldError("PASSWORD", "Incorrect login or password");
                                actions.setSubmitting(false);
                            }
                        }}
                        validationSchema={AuthValidator.GetSchema()}
                >
                    {({isSubmitting, errors, touched, handleSubmit}) => (
                        <Form onSubmit={handleSubmit} autoComplete="on">
                            <LabelStyled htmlFor='LOGIN'>E-mail</LabelStyled>
                            <InputControlStyled name="LOGIN" inputProps={{maxW: "auto"}} pr={8}/>
                            <LabelStyled htmlFor='PASSWORD'>Password</LabelStyled>
                            <InputGroup>
                                <InputControlStyled pr={8}
                                                    name="PASSWORD"
                                                    inputProps={{
                                                        type: showOldPassword ? "text" : "password",
                                                        maxW: "auto",
                                                        autoComplete: "off"
                                                    }}
                                />
                                <InputRightElement>
                                    <ViewOffIcon cursor="pointer" onClick={() => setShowOldPassword(!showOldPassword)}/>
                                </InputRightElement>
                            </InputGroup>
                            <Flex justifyContent={"flex-start"} py={2}>
                                <NavBarLinkButton fontSize={14} fontWeight={"sm"} text="Forgot password?"
                                                  textDecoration="underline"
                                                  onClick={() => navigate(URLPaths.RESTORE_PASSWORD.link)}/>
                            </Flex>
                            <Box {...BoxStyleConfig}>
                                <Flex pt={2}>
                                    <MainButton type="submit" w={basket ? "200px" : "156px"} minHeight={"40px"}
                                                borderColor="gray.500"
                                                border={"2px"}
                                                isLoading={isSubmitting} loadingText="Sent data">
                                        {basket ? 'Login and checkout' : 'Login'}
                                    </MainButton>
                                    {!basket && (
                                        <Box alignContent={"center"} pl={5}>
                                            <NavBarLinkButton text="Registration" textDecoration="underline"
                                                              fontWeight={"md"}
                                                              onClick={() => navigate(URLPaths.ACCOUNT_REGISTRATION.link)}/>
                                        </Box>
                                    )}
                                </Flex>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}
export default AuthorizationForm;