import * as Yup from 'yup';

export class CommonValidators {



    static commonFieldValidator = Yup.string()
        .matches(/^[A-Za-z0-9~!'@#$%^&*()_=\-+><,.|?`\s]+$/, "Field contains invalid characters")
        .min(1, "Field min 1 characters")
        .required("Field cannot be empty");

    static phoneValidator = Yup.string()
        .matches(/^[0-9+]+$/, 'Field must contain only digits and + symbol')
        .min(5, 'Field must be at least 5 characters')
        .max(15, 'Field must be at most 15 characters')
        .required("Field cannot be empty");

    static zipValidator = Yup.string()
        .min(1, "Field cannot be empty")
        .matches(/^\d{5}$/, "Field must be a valid ZIP code")
        .required("Field cannot be empty");

    static passwordValidator = Yup.string()
        .min(6, "Password must be at least 6 characters long.")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
        .matches(/[0-9]/, "Password must contain at least one digit.")
        // .matches(/[~!@#$%^&*()_+=\-{}[\]:;\"'<>,.?/\\|`]/, "Password must contain at least one special character.")
        .matches(/^\S*$/, "Password cannot contain spaces")
        .required("Required field");

    static integerValidator = Yup.number()
        .integer('Value must be an integer')
        .min(1, 'Value must be greater than or equal to 1')
        .max(100000, 'Value must be less than or equal to 100000')
        .required('Required field');

    static percentageValidator = Yup.string()
        .matches(/^\d{1,3}$/, 'Field must contain only digits')
        .test('is-percentage', 'Invalid percentage value', (value: string | undefined) => {
            if (value === undefined) {
                return false; // или true, в зависимости от вашего требования
            }

            const intValue = parseInt(value, 10);
            return !isNaN(intValue) && intValue >= 0 && intValue <= 100;
        })
        .required('Required field');

    static numberValidator = Yup.number()
        .min(0, 'Value must be greater than or equal to 0')
        .max(100000, 'Value must be less than or equal to 100000')
        .test('max-decimal-places', 'Value can have at most 4 decimal places', (value) => {
            if (value === undefined) {
                return false; // или true, в зависимости от вашего требования
            }

            const decimalPlaces = value.toString().split('.')[1]?.length || 0;
            return decimalPlaces <= 4;
        })
        .required('Required field');

    static emailValidator = Yup.string()
        .test(
            'multiple-emails',
            'Invalid email format',
            function (value) {
                if (!value) return true;

                const emails = value.split(',').map((email) => email.trim());

                return emails.every((email) => /^[A-Za-z0-9_.+-]+@[A-Za-z0-9-]+\.[A-Za-z0-9-.]+$/.test(email));
            }
        )
        .required('Required field');
}