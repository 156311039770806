import React, {useState} from "react";
import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    PopoverArrow,
    PopoverCloseButton,
    useToast, Text, IconButton, Box,
} from "@chakra-ui/react";
import {PriceProduct} from "../../shared/entities/Product/Product";

import {OrderProductController} from "../../controllers/OrderProductController";
import {useDispatch} from "react-redux";
import {Loader} from "../../components/Loader/Loader";
import {FixedSizeList as List} from "react-window";
import ReactWindow from "react-window";
import {ProductVendorRow} from "./ProductVendorRow";
import {MainButton} from "../../components/Buttons/MainButton";
import {FiFramer} from "react-icons/all";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {Dealership} from "../../shared/entities/Users/Dealership";

interface AddedProductVendorProps {
    dealership: Dealership;
    userId: number;
}

const AddedProductVendor: React.FC<AddedProductVendorProps> = ({dealership, userId}) => {
    const toast = useToast()
    const [productList, setProductList] = useState<Array<PriceProduct>>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isChange, setIsChange] = useState(false);

    const dispatch = useDispatch();
    const orderProductController = new OrderProductController(dispatch);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false); // State to manage Popover visibility


    const onOpen = () => {
        setIsOpen(true);
        if (dealership) {
            setIsLoading(true);
            if (dealership) {
                orderProductController.getAllProductsFromDealerOrders(Number(dealership.id), {userId: userId}).then((orderProducts) => {
                    setIsLoading(false);
                    if (orderProducts.None) {
                        toast({
                            position: "top",
                            title: 'Error receiving order,' + orderProducts?.None?.getErrorDescription() + ', try logging in',
                            status: 'error',
                            duration: 8000,
                            isClosable: true,
                        })
                    } else {
                        setProductList(orderProducts.Some);
                        setSelectedItems(orderProducts.Some.filter((pp: PriceProduct) => pp.vendorProduct).map(pp => pp.product.id));
                    }
                });
            } else {
                setIsLoading(false);
            }
        }
    };

    const handleOnclickNew = (elem: any, remove: Boolean) => {
        setIsChange(true)
        setSelectedItems(prevSelectedItems => {
            const isSelected = prevSelectedItems.some(item => item === elem.id);
            if (isSelected) {
                return remove ? prevSelectedItems.filter(item => item !== elem.id) : prevSelectedItems;
            } else {
                return [...prevSelectedItems, elem.id];
            }
        });
    };
    const renderRow = ({index, style}: ReactWindow.ListChildComponentProps) => {
        return (
            <ProductVendorRow
                index={index}
                style={style}
                elem={productList[index]}
                selectedItems={selectedItems}
                onToggle={handleOnclickNew}
            />
        );
    };

    const handleSaveClick = () => {
        setIsSubmitting(true)
        orderProductController.addedProductVendor({
            dealershipId: dealership.id,
            userId: userId,
            productIds: selectedItems.map(d => d)
        }).then(res => {
            setIsSubmitting(false)
            setIsChange(false)
        })
    };
    const onClose = () => {
        setIsOpen(false);
        setSelectedItems([]);
    };

    return (
        <Popover isOpen={isOpen} onClose={onClose} placement="left">
            <PopoverTrigger>
                <IconButton bg={"transparent"} _hover={{bg: "transparent"}} _active={{bg: "transparent"}}
                            aria-label="Add product vendor" size={"xs"}>
                    <IconWithTooltip icon={FiFramer} onClick={onOpen} tooltipMessage={'Add product vendor'}/>
                </IconButton>
            </PopoverTrigger>
            <PopoverContent
                style={{
                    maxHeight: "calc(67vh - 20px)",
                    minHeight: "200px",
                    overflowY: "auto",
                    minWidth: "700px",
                    border: "1.5px solid #6B7280",
                    boxShadow: "0px 4px 32px #B3B2B2",
                    overflowX: "hidden",
                }}
            >
                <PopoverCloseButton zIndex="2" ml="auto" position="sticky" onClick={onClose}/>
                <PopoverArrow/>
                {isLoading
                    ? <Loader/>
                    : <>
                        <PopoverBody>
                            <Box mx={"auto"} w={"100%"} borderBottom={"1px"}>
                                <Text textAlign={"center"}
                                      fontSize={16} fontWeight={"md"}>{'All Items Purchased - ' + dealership.name}</Text>
                            </Box>
                            <List
                                height={400} // Высота видимой области
                                itemCount={productList.length} // Количество элементов в списке
                                itemSize={60} // Высота одного элемента
                                width="100%" // Ширина контейнера
                            >
                                {(props) => renderRow(props)}
                            </List>
                        </PopoverBody>
                    </>
                }
                <Box ml="auto" p={4}>
                    <MainButton onClick={handleSaveClick} isLoading={isSubmitting}
                                isDisabled={!isChange}>Accepted</MainButton>
                </Box>
            </PopoverContent>
        </Popover>
    );
};

export default AddedProductVendor;
