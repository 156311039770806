import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {RoleRepositoryInstance} from "../../repository/Role/RoleRepository"
import {ActionTypes} from "../../redux/Role/RoleReducer"
import {Role} from "../../shared/entities/Role/Role"

/**
 * RoleService.
 *
 */
export class RoleService {
    private dispatch: Dispatch<PayloadAction<any, string>>
    private repository = RoleRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any, string>>) {
        this.dispatch = dispatch
    }

    public async createRole(data: Role) {
        return this.repository.createRole(data).then(res => {
            return res
        })
    }

    public async getRole(params?: {}) {
        return this.repository.getRole(params).then(res => {
            this.dispatch({
                type: ActionTypes.ROLE_GET,
                payload: res?.Some?.roles
            })
            return res
        })
    }

    public async getPrivileges(ID?: Array<number>) {
        if (ID?.length !== 0) {
            //@ts-ignore
            ID = ID?.join(",")
        }
        //@ts-ignore
        return this.repository.getPrivileges(ID).then(res => {
            this.dispatch({
                type: ActionTypes.PRIVILEGES_GET,
                payload: res?.Some
            })
            return res
        })
    }

    public async createUpdateRole(data: Role) {
        return this.repository.createUpdateRole(data).then(res => {
            return res
        })
    }

    public async updateRole(data: Role) {
        return this.repository.updateRole(data).then(res => {
            this.dispatch({
                type: ActionTypes.ROLE_UPDATE,
                payload: res?.Some || {},
            })
            return res
        })
    }

    public cleanRole() {
        this.dispatch({
            type: ActionTypes.ROLE_GET,
            payload: []
        })
    }

    public async deleteRole(id: number) {
        return await this.repository.deleteRole(id)
    }
}