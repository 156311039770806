import React from "react";
import {Box, Checkbox, Text} from "@chakra-ui/react";
import {ListItemConfig, ListItemRow} from "../../components/List/ListItem";

interface DealershipRowProps {
    index: number;
    style: React.CSSProperties;
    dealership: any;
    selectedItems: any[];
    onToggle: (dealership: any, remove: boolean) => void;
}

export const DealershipRow: React.FC<DealershipRowProps> = ({index, style, dealership, selectedItems, onToggle}) => {
    if (!dealership) return null;

    const isSelected = selectedItems.some((item) => item.id === dealership.id);

    const ItemConfig: Array<ListItemConfig> = [
        {
            content: <Checkbox font={14} isChecked={isSelected} onChange={() => onToggle(dealership, isSelected)}/>,
            isLink: false,
            style: {flexBasis: "5%", justifyContent: "flex-start", pl: 2},
        },
        {
            content: <Text fontWeight="600">{dealership.name}</Text>,
            isLink: false,
            style: {flexBasis: "95%", justifyContent: "flex-start", pl: 2},
        },
    ];

    return (
        <Box style={style} key={index}>
            <ListItemRow list={ItemConfig}/>
        </Box>
    );
};