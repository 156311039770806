import React, {FC} from "react";
import {Button} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {useAuth} from "../../hooks/AuthHook";
import {useAppSelector} from "../../redux/Store";


interface MenuButtonProps {
    onClick?: () => void;
}

const MenuButton: FC<MenuButtonProps> = ({onClick}) => {
    const user = useAppSelector(store => store.AuthReducer.user);
    const {isAuth} = useAuth();
    const loginText = isAuth ? user?.login : "Log In";

    return (
        <Button
            style={{maxHeight: "35px", fontSize: "14px", fontWeight: "md", background: "transparent"}}
            borderRadius={6}
            _hover={{textDecoration: "underline"}}
            rightIcon={isAuth ? <ChevronDownIcon fontSize={24}/> : <></>}
            onClick={onClick}
        >
            {loginText && loginText.length > 25 ? `${loginText.slice(0, 25)}...` : loginText}
        </Button>
    );
}

export default MenuButton;
