import { Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { Reducer } from "react";
import { BreadcrumbsData } from "../../shared/entities/Breadcrumbs/BreadCrumbsData";

export type BreadcrumbsState = {
    breadcrumbsData: Array<BreadcrumbsData>;
};

const initialState: BreadcrumbsState = {
    breadcrumbsData: [],
};

export enum BreadcrumbsTypes {
    BREADCRUMBS_SET_LINKS = "BREADCRUMBS_SET_LINKS",
    BREADCRUMBS_ADD_LINK = "BREADCRUMBS_ADD_LINK",
}

export const BreadcrumbsReducer: Reducer<BreadcrumbsState, PayloadAction<any, BreadcrumbsTypes>> =
    (state = initialState, action: PayloadAction<any, BreadcrumbsTypes>): BreadcrumbsState => {
        switch (action.type) {
            case BreadcrumbsTypes.BREADCRUMBS_SET_LINKS:
                return {
                    ...state,
                    breadcrumbsData: action.payload,
                };
            case BreadcrumbsTypes.BREADCRUMBS_ADD_LINK:
                const newLink: BreadcrumbsData = action.payload;
                const existingIndex = state.breadcrumbsData.findIndex((breadcrumb) => breadcrumb.link === newLink.link);

                // Оставляем элементы до существующего или добавляем новый
                const updatedBreadcrumbsData =
                    existingIndex !== -1
                        ? state.breadcrumbsData.slice(0, existingIndex + 1)
                        : [...state.breadcrumbsData, newLink];

                return {
                    ...state,
                    breadcrumbsData: updatedBreadcrumbsData,
                };
            default:
                return state;
        }
    };

// Actions
const setBreadcrumbsLink = (payload: Array<BreadcrumbsData>): PayloadAction<Array<BreadcrumbsData>, BreadcrumbsTypes> => ({
    type: BreadcrumbsTypes.BREADCRUMBS_SET_LINKS,
    payload,
});

const addBreadcrumbLink = (newLink: BreadcrumbsData): PayloadAction<BreadcrumbsData, BreadcrumbsTypes> => ({
    type: BreadcrumbsTypes.BREADCRUMBS_ADD_LINK,
    payload: newLink,
});

// Usage Example
export const setBreadcrumbsLinks = (
    dispatch: Dispatch<PayloadAction<Array<BreadcrumbsData>, BreadcrumbsTypes>>,
    payload: Array<BreadcrumbsData>
) => {
    dispatch(setBreadcrumbsLink(payload));
};

export const addedBreadcrumbsLinks = (
    dispatch: Dispatch<PayloadAction<BreadcrumbsData, BreadcrumbsTypes>>,
    newLink: BreadcrumbsData
) => {
    dispatch(addBreadcrumbLink(newLink));
};
