import React, {FC} from 'react';
import {AspectRatio, Box, Flex, Text} from '@chakra-ui/react';
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {Card} from "../../components/Card/Card";

const GreatSave: FC<{ links: Array<BreadcrumbsData> }> = (props) => {

    const GreatSaveCardConfig = {
        marginX: "auto",
        minHeight: "781px",
        pageTop: 5,
        background: "primary.gray.light",
        borderRadius: "9px",
        w: {
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%"
        },
        h: {
            sm: "100%",
            lg: "100%"
        },
        position: "relative",
        justifyContent: "center"
    }


    return (
        <Card {...GreatSaveCardConfig} direction={{base: "column", lg: "row"}}>
            <Box py={2} px={{lg: 5}} height={"100%"}>
                <Flex pb={5} flexDir={{base: "column", lg: "row"}}>
                    <Box w={{base: "100%", lg: "40%"}} marginY={"auto"}>
                        <Flex pb={{base: 2, lg: "unset"}}>
                            <Text fontWeight={"md"} fontSize={{base: 40, lg: 65}} mt={-2}>$</Text>
                            <Text fontSize={{base: 65, lg: 80, xl: 112}} fontWeight={"md"} lineHeight={"110%"}
                                  letterSpacing={"-0.05em"}>250,000</Text>
                        </Flex>
                        <Text fontSize={14} fontWeight={"sm"} lineHeight={"0%"}
                              textAlign={{base: "left", lg: "center"}}>You save with us
                            during the year</Text>
                    </Box>
                    <Box w={{base: "100%", lg: "60%"}} pl={{lg: 15}} pt={{base: 8, lg: "unset"}}>
                        <Text fontSize={{base: 38, lg: 42}} fontWeight={"md"} letterSpacing={"-0.02em"}
                              lineHeight={"110%"}
                              color={"red.base"}>GREAT SAVING</Text>
                        <Text fontSize={42} fontWeight={"md"} letterSpacing={"-0.02em"} lineHeight={"110%"}>We have
                            better offers</Text>
                        <Text pt={3} fontSize={20} fontWeight={"sm"} lineHeight={"140%"}>Save big with our services!
                            Join
                            us and you could slash your
                            annual expenses by up to $250,000. Discover how much you can save today and maximize your
                            budget like never before. Let's make your money work smarter!</Text>
                    </Box>
                </Flex>
                <Box py={4} display={{base: "none", lg: "block"}}>
                    <AspectRatio maxW='846px' maxH={"465px"} ratio={1} marginX={"auto"}>
                        <iframe
                            title='naruto'
                            src='https://www.youtube.com/embed/esTzImp-P5A'
                            allowFullScreen
                            style={{borderRadius: '5px'}}
                        />
                    </AspectRatio>
                </Box>
            </Box>
            <Box py={2} display={{base: "block", lg: "none"}}>
                <AspectRatio ratio={1} marginX={"auto"} maxH={"172px"}>
                    <iframe
                        title='naruto'
                        src='https://www.youtube.com/embed/esTzImp-P5A'
                        allowFullScreen
                        style={{borderRadius: '1px'}}
                    />
                </AspectRatio>
            </Box>
        </Card>
    );
}

export default GreatSave;
