import {
    Box, Button, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter, ModalHeader,
    ModalOverlay, useDisclosure, useToast
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "../../redux/Store";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {MainButton} from "../../components/Buttons/MainButton";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import {LabelStyled} from "../../components/Inputs/LabelStyled";
import {Company} from "../../shared/entities/Company/Company";
import {InputControlStyled} from "../../components/Inputs/InputControlStyled";
import {Form, Formik} from "formik";
import {SelectControlStyled} from "../../components/Inputs/SelectControlStyled";
import {RoleController} from "../../controllers/RoleController";
import {UserController} from "../../controllers/UserController";
import {User} from "../../shared/entities/Users/User";
import ReactWindow, {FixedSizeList as List} from "react-window";
import {DealershipRow} from "./DealershipRowProps";
import {hasPermission} from "../../features/Permission/AllowedAccess";
import {clientCreatePermission, clientEditPermission} from "../permissions";

interface AddUserModalProps {
    onRefresh: () => void;
}

export const AddUserModal: FC<AddUserModalProps> = ({onRefresh}) => {
    const event = useAppSelector(state => state.ModalReducer.event);
    const dealershipsMinInfo = useAppSelector(state => state.UserReducer.dealershipsMinInfo);
    const dispatch = useDispatch();
    const modalService = new ModalService(dispatch);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [commonContent, setCommonContent] = useState<Company>()
    const dispath = useDispatch()
    const roleController = new RoleController(dispath)
    const roles = useAppSelector(store => store.RoleReducer?.roleList)

    const {isOpen, onOpen, onClose} = useDisclosure()

    const [filterDealerships, setFilterDealerships] = useState<Array<any>>([])
    const debug = process.env.NODE_ENV === "development";
    const [currentRoleId, setCurrentRoleId] = useState<number>(3)
    const [editId, setEditId] = useState<number>(-1)
    const userController = new UserController(dispatch);
    const toast = useToast();
    const [searchTerm, setSearchTerm] = useState('');
    const [initialValues, setInitialValues] = useState<any>();

    useEffect(() => {
        if (event && event.selModel === SelectModal.ADD_USER) {
            setCommonContent(event.selCommonContent)
            setEditId(event.selId);
            if (event.selId !== -1) {
                let userCurrent = event.selCommonContent.users.find((c: User) => c.id === event.selId)
                setInitialValues({
                    login: userCurrent.login,
                    email: userCurrent.email,
                    phone: userCurrent.phone,
                    roleCurrent: userCurrent.role.id
                })
                setCurrentRoleId(userCurrent.role.id);
                setSelectedItems(userCurrent.dealerships);
            } else {
                setCurrentRoleId(3);
                setInitialValues({
                    login: "", email: "", roleCurrent: 3,
                })
                setSelectedItems([])

            }
            roleController.getRole()
            onOpen()
        } else {
            onClose()
        }
    }, [event]);

    useEffect(() => {
        let isDealershipExclude = roles?.find(r => r.id === Number(currentRoleId))?.permissions.some(p => p.actions.includes('select') && p.group === 'Dealership_Exclude') || false;
        let dealers = isDealershipExclude ? dealershipsMinInfo : commonContent?.dealerships.map(v => ({
            id: v.id,
            name: v.name
        }));

        if (dealers) {
            if (searchTerm) {
                setFilterDealerships(dealers.filter(dealer =>
                    dealer.name.toLowerCase().includes(searchTerm.toLowerCase())
                ))
            } else {
                setFilterDealerships(dealers)
            }
        }
    }, [searchTerm, commonContent, dealershipsMinInfo, currentRoleId, roles]);

    const handleOnclickNew = (elem: any, remove: Boolean) => {
        setSelectedItems(prevSelectedItems => {
            const isSelected = prevSelectedItems.some(item => item.id === elem.id);
            if (isSelected) {
                return remove ? prevSelectedItems.filter(item => item.id !== elem.id) : prevSelectedItems;
            } else {
                return [...prevSelectedItems, elem];
            }
        });
    };

    const renderRow = ({index, style}: ReactWindow.ListChildComponentProps) => {
        const dealership = filterDealerships[index];
        return (
            <DealershipRow
                index={index}
                style={style}
                dealership={dealership}
                selectedItems={selectedItems}
                onToggle={handleOnclickNew}
            />
        );
    };
    const handleChange = (event: any) => {
        setSearchTerm(event.target.value);
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                modalService.resetModal();
                onClose();
            }}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{editId === -1 ? 'Create user' : 'Update user'}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            if (values.copyShippingToBilling) {
                                delete values.billingAddress;
                            }
                            if (editId === -1) {
                                if (hasPermission(clientCreatePermission)) {
                                    actions.setSubmitting(true)
                                    userController.createUser(values).then(res => {
                                        if (res.None) {
                                            if (res?.None?.getErrorCode() === 10202) {
                                                actions.setFieldError("login", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                            if (res?.None?.getErrorCode() === 10203) {
                                                actions.setFieldError("email", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                        } else {
                                            toast({
                                                position: "top",
                                                title: 'Your user was created successfully',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onRefresh()
                                            modalService.resetModal();
                                            onClose();
                                        }
                                    })
                                }
                            } else {
                                values.editUserId = editId
                                if (hasPermission(clientEditPermission)) {
                                    actions.setSubmitting(true)
                                    userController.updateUser(values).then(res => {
                                        if (res.None) {
                                            if (res?.None?.getErrorCode() === 10202) {
                                                actions.setFieldError("login", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                            if (res?.None?.getErrorCode() === 10203) {
                                                actions.setFieldError("email", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                        } else {
                                            toast({
                                                position: "top",
                                                title: 'User was update successfully',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onRefresh()
                                            modalService.resetModal();
                                            onClose();
                                        }
                                    })
                                }
                            }
                            actions.setSubmitting(false)
                        }}
                        validate={values => {
                            values.roleIds = [currentRoleId];
                            values.companyId = commonContent?.id;
                            values.dealerships = selectedItems.map(d => ({id: d.id, name: d.name}));
                            setInitialValues(values)
                        }}
                        enableReinitialize={true}
                    >
                        {({isSubmitting, touched, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                            <Form onSubmit={handleSubmit}>
                                <LabelStyled>Name</LabelStyled>
                                <InputControlStyled name="login" inputProps={{
                                    maxW: "100%",
                                    autoComplete: debug ? "on" : "new-password"
                                }}/>
                                <LabelStyled>E-mail</LabelStyled>
                                <InputControlStyled name="email" inputProps={{
                                    maxW: "100%",
                                    autoComplete: debug ? "on" : "new-password"
                                }}
                                />
                                <LabelStyled>Role</LabelStyled>
                                <SelectControlStyled
                                    selectProps={{fontSize: 14, mt: 1}}
                                    name="roleCurrent"
                                    onChange={(ev: any) => setCurrentRoleId(ev.target.value)}
                                >
                                    {roles?.map((role) => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </SelectControlStyled>
                                {/*</Box>*/}
                                {selectedItems.length > 0 && (
                                    <Box py={1} display="flex" flexWrap="wrap" maxHeight={"150px"}
                                         overflowY={"auto"}>
                                        {selectedItems.map((item) => (
                                            <Button
                                                maxHeight={6}
                                                key={item.id}
                                                mr="1"
                                                colorScheme="teal"
                                                variant="solid"
                                                size="sm"
                                                mb={"1"}
                                                fontSize={12}
                                                onClick={() => handleOnclickNew(item, true)}
                                            >
                                                {item.name} ✕
                                            </Button>
                                        ))}
                                    </Box>
                                )}

                                <Input mt={2}
                                       fontSize={14}
                                       height={30}
                                       placeholder="Search dealership"
                                       value={searchTerm}
                                       onChange={handleChange}
                                />
                                <Box>
                                    <LabelStyled>Access dealership for user</LabelStyled>
                                    <Box minWidth={"100%"}>
                                        <Box maxHeight={"300px"} pt={1} border={"1px"}
                                             borderRadius={"5px"}>
                                            <List
                                                height={280} // Высота видимой области
                                                itemCount={filterDealerships.length} // Количество элементов в списке
                                                itemSize={30} // Высота одного элемента
                                                width="100%" // Ширина контейнера
                                            >
                                                {(props) => renderRow(props)}
                                            </List>
                                        </Box>
                                    </Box>
                                    <ModalFooter>
                                        <MainButton
                                            type="submit"
                                            isLoading={isSubmitting}
                                            // disabled={selectedItems.length === 0 && (Number(currentRole) === 3)}
                                        > Save </MainButton>
                                    </ModalFooter>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
