import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {Product} from "../../../shared/entities/Product/Product";
import EditButton from "../../../components/Buttons/EditButton";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import ProductVideo from "../ProductVideo";
import {generalBox} from "../../../theme/foundations/breakpoints";
import {CardBody} from "../../../components/Card/CardBody";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import CustomLink from "../../../components/Link/CustomLink";
import AllowedAccess, {hasPermission} from "../../../features/Permission/AllowedAccess";
import {productEditPermission} from "../../permissions";

interface CategorySectionProps {
    product: Product;
    isMobile?: boolean;
}

const CategoryGeneralItem: React.FC<CategorySectionProps> = ({product, isMobile = false}) => {

    return (
        <Flex w={"100%"} height={"100%"}>
            <AllowedAccess permissionKey={productEditPermission} localPermission={!isMobile}>
                <DragAndDropBox item={product} width={"5%"} orderByZone={true}/>
            </AllowedAccess>
            <CardBody>
                <DragAndDropBox item={product} isDraggable={hasPermission(productEditPermission)}>
                    {product.id !== -1 && <EditButton item={product}/>}
                    <CustomLink href={WebPUtils.getProductLink(product)}>
                        <Flex p={6} height={generalBox.category.heightTitle}>
                            <Flex alignItems="center">
                                <Text
                                    fontSize="2rem" fontWeight={"md"}
                                    letterSpacing={"-0.04rem"}
                                    lineHeight={"2rem"}
                                    cursor={"pointer"}
                                >
                                    {product.name}
                                </Text>
                                <Box pl={3}>
                                    <ProductVideo video={product.video[0]}/>
                                </Box>
                            </Flex>
                        </Flex>
                    </CustomLink>
                    <ImageCustom elem={product} currentSize={"general"} sizeBackend={"GENERAL"} isMobile={isMobile}/>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};

export default CategoryGeneralItem;
