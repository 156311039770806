import React from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {Content, Product} from '../../../shared/entities/Product/Product';
import EditButton from "../../../components/Buttons/EditButton";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import ProductVideo from "../ProductVideo";
import {CardBody} from "../../../components/Card/CardBody";
import {MainButton} from "../../../components/Buttons/MainButton";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import CustomLink from "../../../components/Link/CustomLink";
import {imageSizes} from "../../../theme/foundations/breakpoints";
import {productEditPermission} from "../../permissions";
import AllowedAccess from "../../../features/Permission";
import {hasPermission} from "../../../features/Permission/AllowedAccess";

interface AnotherGeneralItemProps {
    product: Product;
    isMobile?: boolean;
}

const AnotherGeneralItem: React.FC<AnotherGeneralItemProps> = ({product, isMobile = false}) => {

    return (
        <Flex height={"100%"}>
            <Box display="flex" direction={{base: "column", md: "row"}} width={"100%"}>
                <AllowedAccess permissionKey={productEditPermission} localPermission={!isMobile}>
                    <DragAndDropBox item={product} width={"2%"} orderByZone={true} height={"100%"}/>
                </AllowedAccess>
                <CardBody>
                    <DragAndDropBox item={product} isDraggable={hasPermission(productEditPermission)} row={true}>
                        <Flex h={"100%"} direction={{base: "column", md: "row"}} width={"100%"}>
                            <Box pl={10} py={6} pr={6}>
                                <EditButton item={product}/>
                                <Flex pb={2}>
                                    <CustomLink href={WebPUtils.getProductLink(product)}
                                                forceExternal={true}>
                                        <Text fontSize={22} fontWeight={"md"} lineHeight={"110%"}>
                                            {product.name}
                                        </Text>
                                    </CustomLink>
                                </Flex>
                                {product.video.length > 0 && (
                                    product.video
                                        .sort((a, b) => a.order - b.order)
                                        .map((video: Content, idx: number) => {
                                            return <ProductVideo key={video.id || idx} title={true} video={video}/>
                                        })
                                )}
                                <Text fontWeight={"sm"} fontSize={14} lineHeight={"150%"}>
                                    {product.description}
                                </Text>
                            </Box>
                            <Box ml={{base: "unset", md: "auto"}}>
                                <Box mx={"auto"} w={"100%"}
                                     justifyContent={{base: "center", md: "flex-end"}}
                                     minWidth={`${imageSizes["another"].width}px`}>
                                    <ImageCustom elem={product} currentSize={"another"} isMobile={isMobile}
                                                 forceExternal={true}/>
                                </Box>
                                <Flex p={6} justifyContent="center">
                                    <CustomLink href={WebPUtils.getProductLink(product)}
                                                forceExternal={true}>
                                        <MainButton>{product.name === 'Nitrogen' ? 'Order' : 'Read More'}</MainButton>
                                    </CustomLink>
                                </Flex>
                            </Box>
                        </Flex>
                    </DragAndDropBox>
                </CardBody>
            </Box>
        </Flex>
    );
};

export default AnotherGeneralItem;
