import {PayloadAction} from "@reduxjs/toolkit";
import {Reducer} from "react";
import {AuthorizationData} from "../../shared/entities/Auth/AuthorizationData";
import {Dealership} from "../../shared/entities/Users/Dealership";
import {User} from "../../shared/entities/Users/User";

export type AuthState = {
    endUserData: AuthorizationData | null
    dealership: Dealership | null,
    user: User | null,
    publicKey: any,
}

const State: AuthState = {
    endUserData: null,
    dealership: null,
    user: null,
    publicKey: null
}

export enum AuthTypes {
    AUTH_SET_TOKEN = "AUTH_SET_TOKEN",
    SET_DEALERSHIP = "SET_DEALERSHIP",
    GET_AUTH = "GET_AUTH",
    LOG_OUT = "LOG_OUT",
    AUTH_DELETE_TOKEN = "AUTH_DELETE_TOKEN",
    PUBLIC_KEY = "PUBLIC_KEY"
}

export const AuthReducer: Reducer<AuthState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): AuthState => {
    switch (action.type) {
        case AuthTypes.AUTH_SET_TOKEN:
            return state = {
                ...state,
                endUserData: action.payload
            }

        case AuthTypes.AUTH_DELETE_TOKEN:
            return state = {
                ...state,
                endUserData: null
            }
        case AuthTypes.SET_DEALERSHIP:
            return state = {
                ...state,
                dealership: action.payload
            }
        case AuthTypes.GET_AUTH:
            return state = {
                ...state,
                user: action.payload
            }
        case AuthTypes.LOG_OUT:
            return state = {
                ...state,
                user: null,
                dealership: null
            }
        case AuthTypes.PUBLIC_KEY:
            return state = {
                ...state,
                publicKey: action.payload
            }
        default:
            return state
    }
}

