import { Dispatch } from "@reduxjs/toolkit";
import {ImageItem} from "../shared/entities/Product/Product";
import {ImageActionTypes} from "../redux/Image/ImageReducer";

export class ImageService {
    private dispatch: Dispatch;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }

    /**
     * Добавить изображения
     * @param images массив объектов типа ImageItem
     */
    public addImages(images: ImageItem[]) {
        this.dispatch({
            type: ImageActionTypes.ADD_IMAGES,
            payload: images,
        })
    }

    /**
     * Обновить конкретное изображение
     * @param index индекс изображения для обновления
     * @param image новые данные изображения
     */
    public updateImage(index: number, image: ImageItem) {
        this.dispatch({
            type: ImageActionTypes.UPDATE_IMAGE,
            payload: { index, image },
        })
    }

    /**
     * Удалить изображение по индексу
     * @param index индекс удаляемого изображения
     */
    public removeImage(index: number) {
        this.dispatch({
            type: ImageActionTypes.REMOVE_IMAGE,
            payload: index,
        })
    }

    /**
     * Очистить все изображения
     */
    public clearImages() {
        this.dispatch({
            type: ImageActionTypes.CLEAR_IMAGES,
        })
    }
}
