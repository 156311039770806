import React from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {Icon} from "@chakra-ui/icons";
import {URLPaths} from "../../config/application/URLPaths";
import {Product} from "../../shared/entities/Product/Product";
import {useNavigate, useParams} from "react-router";
import {PlusNewItem} from "../../components/Inputs/Icons/Icons";
import {CardBody} from "../../components/Card/CardBody";
import {productEditPermission} from "../permissions";
import AllowedAccess from "../../features/Permission";
import {hasPermission} from "../../features/Permission/AllowedAccess";

type NewProductComponentProps = {
    product: Product;
    height?: string;
};

const NewProductComponent: React.FC<NewProductComponentProps> = ({product, height = "100%"}) => {
    const {urlItem} = useParams();
    const id = urlItem?.split("-")[0];
    const navigate = useNavigate();
    const productId = id ? Number(id) : 0;

    return (
        <Flex height={height}>
            <AllowedAccess permissionKey={productEditPermission}>
                <Box width={"5%"}/>
            </AllowedAccess>
            <CardBody width={hasPermission(productEditPermission) ? "95%" : "100%"}>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    cursor={"pointer"}
                    onClick={() => navigate(`${URLPaths.PRODUCTS_CREATE.link}${productId}`)}
                >
                    <Icon as={PlusNewItem} fontSize={"xl"} color="grey.500"/>
                </Flex>
            </CardBody>
        </Flex>
    );
};

export default NewProductComponent;