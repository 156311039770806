import {
    FormControl, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter, ModalHeader,
    ModalOverlay, Select, useDisclosure, useToast
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "../../redux/Store";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {useFormikContext} from "formik";
import {MainButton} from "../../components/Buttons/MainButton";
import {Discount, ContentTypes, ContentTypeUtils} from "../../shared/entities/Product/Product";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";

export const DiscountModal: FC = () => {
    const eventDiscount = useAppSelector(state => state.ModalReducer.event);
    const dispatch = useDispatch();
    const [value, setValue] = useState<number>(0)
    const [commonContent, setCommonContent] = useState<Array<any>>([])
    const [index, setIndex] = useState<number>(-1)
    const [selectedContentType, setSelectedContentType] = useState<string>(ContentTypes.DISCOUNT_DEALER);
    const [selectedDealer, setSelectedDealer] = useState<any>(0);
    const modalService = new ModalService(dispatch);

    const formik = useFormikContext()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const toast = useToast()


    const dealerships = useAppSelector(state => state.UserReducer.dealershipsMinInfo);

    useEffect(() => {
        if (eventDiscount && eventDiscount.selModel === SelectModal.DISCOUNT) {
            setCommonContent(eventDiscount.selCommonContent)
            setIndex(eventDiscount.index)
            if (eventDiscount.index >= 0) {
                let selContentCommon = eventDiscount.selCommonContent[eventDiscount.index]
                setSelectedContentType(selContentCommon.type)
                setValue(selContentCommon.value)
                setSelectedDealer(dealerships.find(d => d.id === selContentCommon?.dealershipId)?.id)
            } else {
                setSelectedContentType(ContentTypes.DISCOUNT_DEALER)
                setValue(0)
                setSelectedDealer(0)
            }
            onOpen()
        } else {
            onClose()
        }
    }, [eventDiscount]);
    const toastExecute = () => {
        toast({
            position: "top",
            title: 'Value applied',
            description: 'It is impossible for one product to have a markup and a discount, so the values will be replaced for the existing element',
            status: 'warning',
            duration: 5000,
            isClosable: true,
        })
    }

    const handleSaveClick = () => {
        let currentDealer = selectedDealer === 0 ? dealerships[0] : dealerships.find((elem) => elem.id === Number(selectedDealer));
        let settingDealers = commonContent.filter(v => v.title === currentDealer?.name)
        const newDealer = [...commonContent];
        let updateIndex = index;

        let existElem: Discount | undefined;
        if (settingDealers.find(v => v.type === selectedContentType)) {
            existElem = settingDealers.find(v => v.type === selectedContentType)
            // toastExecute()
        }
        if (selectedContentType === ContentTypes.MARKUP_DEALER && settingDealers.find(v => v.type === ContentTypes.DISCOUNT_DEALER)) {
            existElem = settingDealers.find(v => v.type === ContentTypes.DISCOUNT_DEALER)
            toastExecute()
        }
        if (selectedContentType === ContentTypes.DISCOUNT_DEALER && settingDealers.find(v => v.type === ContentTypes.MARKUP_DEALER)) {
            existElem = settingDealers.find(v => v.type === ContentTypes.MARKUP_DEALER)
            toastExecute()
        }
        if (existElem) {
            updateIndex = newDealer.findIndex(v => v.id === existElem?.id);
        }

        if (updateIndex >= 0) {
            newDealer[updateIndex].value = value;
            newDealer[updateIndex].title = currentDealer?.name;
            newDealer[updateIndex].type = selectedContentType;
            formik.setFieldValue('commonContent', newDealer);
        } else {
            formik.setFieldValue('commonContent', [...commonContent, {
                id: 0,
                dealershipId: currentDealer?.id,
                value: value,
                type: selectedContentType,
                // order: 1
            }])
        }
        modalService.resetModal();
        onClose();
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Discount product for dealership</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <FormControl>
                        <FormLabel>Select dealership</FormLabel>
                        <Select
                            value={selectedDealer}
                            isDisabled={index >= 0}
                            onChange={(event) => setSelectedDealer(event.target.value)}
                        >
                            {dealerships.map((elem: any) => (
                                <option key={elem.id} value={elem.id}>
                                    {elem.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl pt={2}>
                        <FormLabel>Select Content Type</FormLabel>
                        <Select
                            isDisabled={index >= 0}
                            defaultValue={selectedContentType}
                            onChange={(event) => setSelectedContentType(event.target.value)}>
                            {Object.values(ContentTypes).map((type) => (
                                <option key={type} value={type}>
                                    {ContentTypeUtils.getCostState(type).name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl pt={2}>
                        <FormLabel>Value (%)</FormLabel>
                        <Input type="number" value={value} onChange={(ev: any) => setValue(ev.target.value)}/>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <MainButton onClick={handleSaveClick} disabled={value > 1000}> Save </MainButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
