import React, {FC} from 'react';
import {Link as ReactRouterLink} from 'react-router-dom';
import {ChakraProps, Link as ChakraLink} from '@chakra-ui/react';

interface CustomLinkProps extends ChakraProps {
    href: string;
    children: React.ReactNode;
    forceExternal?: boolean;
}


const CustomLink: FC<CustomLinkProps> = ({href, children, forceExternal = false, ...props}) => {
    const isExternal = forceExternal || href.startsWith(`${window.location.origin}/articles`) || (href.startsWith('http') && !href.startsWith(window.location.origin));
    return (
        <ChakraLink {...props}
                    as={isExternal ? undefined : ReactRouterLink}
                    to={isExternal ? "unset" : href.startsWith(window.location.origin) ? href.substring(origin.length) : href}
                    href={isExternal ? href : undefined}
                    isExternal={isExternal}
        >
            {children}
        </ChakraLink>
    );
};

export default CustomLink;
