import React, {useState} from "react";
import {
    Box,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    IconButton, PopoverCloseButton,
} from "@chakra-ui/react";

import {useDispatch} from "react-redux";
import {FiDownload} from "react-icons/all";
import {Loader} from "../../components/Loader/Loader";
import {ListItemConfig, ListItemRow} from "../../components/List/ListItem";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {FixedSizeList as List} from "react-window";
import * as ReactWindow from "react-window";
import {UserController} from "../../controllers/UserController";
import {FilterRequest, User} from "../../shared/entities/Users/User";
import {DownloadButton} from "../../components/Buttons/DownloadButton";
import {Company} from "../../shared/entities/Company/Company";

interface ExcludeSubscribePopoverProps {
    item: any;
    onSuccess?: () => void;
    clickAction: Function,
}

interface CompaniesResponse {
    companies: Company[];
    count: number;
}

const LoadStatementVendorPopover: React.FC<ExcludeSubscribePopoverProps> = ({onSuccess, clickAction}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false); // Добавим состояние загрузки
    const userController = new UserController(dispatch)
    const [listAll, setListAll] = useState<Array<User>>([])

    const onOpen = () => {
        const filterRequest: FilterRequest = {
            user: {name: "", email: ""},
            dealer: {name: "", email: ""},
            company: {name: "Administrators", email: ""},
        };
        setIsLoading(true)
        userController.getCompanyAll({page: 0, size: 100}, filterRequest).then(res => {
            setIsLoading(false)
            if (res.Some) {
                if ((res.Some as CompaniesResponse).companies[0]) {
                    const filteredUsers = (res.Some as CompaniesResponse).companies[0].users.filter((u) =>
                        u.role.permissions.some(p => p.actions.includes('select') && p.group === 'Dealership_Exclude')
                    );
                    setListAll(filteredUsers)
                }
            }

        })
    };

    const renderRow = ({index, style}: ReactWindow.ListChildComponentProps) => {
        const elem = listAll[index];

        if (!elem) return null;
        const ItemConfig: Array<ListItemConfig> = [
            {
                content: elem.id,
                isLink: false,
                style: {
                    flexBasis: "15%",
                    justifyContent: "flex-start",
                },
            },
            {
                content: elem.login,
                isLink: false,
                style: {
                    flexBasis: "65%",
                    justifyContent: "flex-start",
                },
            },
            {
                content: (
                    <DownloadButton clickAction={() => clickAction(true, elem.id)}
                                    label={"Load Vendor Statement Excel"}
                                    icon={FiDownload}
                                    nameFile={"vendor-statement.xlsx"}/>
                ),
                isLink: true,
                style: {
                    flexBasis: "10%",
                },
            },

        ];

        return (
            <Box style={style} key={index}>
                <ListItemRow list={ItemConfig}/>
            </Box>
        );
    };

    return (
        <Popover closeOnBlur={true} onOpen={onOpen}>
            <PopoverTrigger>
                <IconButton bg={"transparent"} _hover={{bg: "transparent"}} _active={{bg: "transparent"}} pt={4}
                            aria-label="Load Vendor Statement" size={"xs"}>
                    <IconWithTooltip icon={FiDownload} tooltipMessage={'Add product vendor'} fontSize={"22"}/>
                </IconButton>
            </PopoverTrigger>

            <PopoverContent
                style={{
                    padding: 8,
                    maxHeight: "calc(67vh - 20px)",
                    overflowY: "auto",
                    border: "1.5px solid #6B7280",
                    boxShadow: "0px 4px 32px #B3B2B2",
                    minWidth: "50%",
                }}
            >
                <PopoverCloseButton zIndex="2" ml="auto" position="sticky"/>
                <PopoverBody p={0}>
                    <Box position="relative">
                        <Box pl={3}>
                            {isLoading ? (
                                <Loader/>
                            ) : (
                                <List
                                    height={100} // Высота видимой области
                                    itemCount={listAll.length} // Количество элементов в списке
                                    itemSize={40} // Высота одного элемента
                                    width="100%" // Ширина контейнера
                                >
                                    {(props) => renderRow(props)}
                                </List>
                            )}
                        </Box>
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default LoadStatementVendorPopover;
