import React, {FC} from "react";
import {Box} from "@chakra-ui/react";
import {URLPaths} from "../../config/application/URLPaths";
import {CustomLink} from "./CustomLink";
import {useNavigate} from "react-router";
import {AuthorizationController} from "../../controllers/AuthController";
import {useDispatch} from "react-redux";
import {useAuth} from "../../hooks/AuthHook";
import AllowedAccess from "../../features/Permission/AllowedAccess";
import {
    accountPermission,
    clientViewPermission,
    roleViewPermission
} from "../permissions";


const MenuLinks: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {handleAuthChange} = useAuth();

    const authController = new AuthorizationController(dispatch);

    const LogOut = () => {
        authController.logOut().then((res) => {
            sessionStorage.clear();
            handleAuthChange(false);
            window.location.reload();
            dispatch({type: "LOG_OUT"});
        });
        navigate(URLPaths.HOME.link);
    };

    const handleLinkClick = (path: string) => {
        navigate(path);
    };
    return (
        <Box fontSize={14} textAlign="left">
            <AllowedAccess permissionKey={accountPermission}>
                <CustomLink onClick={() => handleLinkClick(URLPaths.ACCOUNT.link)}>Account</CustomLink>
            </AllowedAccess>
            <AllowedAccess permissionKey={clientViewPermission}>
                <CustomLink onClick={() => handleLinkClick(URLPaths.COMPANIES.link)}>Company list</CustomLink>
            </AllowedAccess>
            <AllowedAccess permissionKey={roleViewPermission}>
                <CustomLink onClick={() => handleLinkClick(URLPaths.ROLES.link)}>Role list</CustomLink>
            </AllowedAccess>
            <CustomLink onClick={LogOut}>Log Out</CustomLink>
        </Box>
    );
}

export default MenuLinks;
