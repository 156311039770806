import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Box, Button, Flex, useBreakpointValue} from "@chakra-ui/react";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {setBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {URLPaths} from "../../config/application/URLPaths";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {PersonalInformation} from "./PersonalInformation";
import OrderBasket from "../Order/OrderBasket";
import {ChangePassword} from "../Forms/Accounts/ChangePassword";
import {AuthorizationController} from "../../controllers/AuthController";
import {useAuth} from "../../hooks/AuthHook";
import {useNavigate} from "react-router";
import {useAppSelector} from "../../redux/Store";


export const Account: FC<{ links: Array<BreadcrumbsData> }> = ({links}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {handleAuthChange} = useAuth();
    const authController = new AuthorizationController(dispatch);
    const [selectedComponent, setSelectedComponent] = useState<string>("PersonalInformation");
    const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false});
    setBreadcrumbsLinks(dispatch, links);
    const LogOut = () => {
        authController.logOut().then((res) => {
            sessionStorage.clear();
            handleAuthChange(false);
            navigate(URLPaths.HOME.link);
        })
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case "PersonalInformation":
                return <PersonalInformation/>;
            case "OrderBasket":
                return <OrderBasket account={true}/>;
            case "ChangePassword":
                return <ChangePassword links={links}
                                       onPasswordChange={() => setSelectedComponent("PersonalInformation")}/>;
            default:
                return null;
        }
    }
    const buttons = [
        {label: 'Personal Information', component: "PersonalInformation"},
        {label: 'Cart', component: "OrderBasket", show: cartItems.length > 0 && !isMobile},
        {label: 'Change Password', component: "ChangePassword"},
        {label: 'Log Out', component: "LogOut"}
    ];

    return (
        <>
            <Breadcrumbs/>
            <Box pt={4}>
                <Flex direction={{base: "column", lg: "row"}}>
                    {/*<Box pt={10} maxW={"290px"} display={{base: "none", lg: "block"}}>*/}
                    <Box maxW={"290px"}>
                        {buttons.filter(button => button.show !== false).map((button, index) => (
                            <Button
                                px={2}
                                key={index}
                                width='100%'
                                border="unset"
                                borderRadius="5px"
                                variant='outline'
                                h={10}
                                fontSize={14}
                                fontWeight="sm"
                                onClick={() => {
                                    if (button.component === "LogOut") {
                                        LogOut();
                                    } else {
                                        setSelectedComponent(button.component);
                                    }
                                }}
                                textAlign="left"
                                justifyContent="flex-start"
                                backgroundColor={selectedComponent === button.component ? "gray.100" : "white"}
                                _hover={{backgroundColor: "gray.200"}}
                            >
                                {button.label}
                            </Button>
                        ))}
                    </Box>
                    <Box pl={{base: 0, lg: 10}} pt={{base: 4, lg: 0}} w={"100%"}>
                        {renderComponent()}
                    </Box>
                </Flex>
            </Box>
        </>
    );
}
export default Account;