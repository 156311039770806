import React from 'react';
import _ from 'lodash';

import {store, useAppSelector} from "../../redux/Store";
import {User} from "../../shared/entities/Users/User";
import {Permission} from "../../shared/entities/Role/Permission";

export const checkLocalPermission = (permission: string, permissionList: string[]): boolean => _.includes(permissionList, permission);

/* Функция проверки прав по дереву прав */
export const checkPermission = (permissions: any, permissionKey: string): boolean => {
    return permissions.includes(permissionKey);
};

/**
 * Логика: Проверка на наличие какого-то разрешения у текущего пользователя
 * @param {string | string[]} rule - правило(а)
 * @return {boolean}
 */
export const hasPermission = (rule: string | string[]): boolean => {
    const user = store?.getState()?.AuthReducer?.user;
    const permissions: string[] = extractPermissions(user);
    if (_.isArray(rule)) { // принимает массив строк или строку
        for (let i = 0; i < rule.length; i++) {
            if (checkPermission(permissions, rule[i])) {
                return true;
            }
        }
        return false;
    } else {
        return checkPermission(permissions, rule);
    }
};
const AllowedAccess: ({permissionKey, children, localPermission}: {
    permissionKey: string;
    children: React.ReactNode;
    localPermission?: boolean;
}) => JSX.Element | null = ({permissionKey, children, localPermission}) => {
    const user = useAppSelector(store => store.AuthReducer.user)
    const permissions = extractPermissions(user)
    const isAllowed = _.isBoolean(localPermission)
        ? checkPermission(permissions, permissionKey) && localPermission
        : checkPermission(permissions, permissionKey);

    return isAllowed ? <>{children}</> : <></>;
};

function extractPermissions(user: User | null): string[] {
    if (!user?.role?.permissions) {
        return [];
    }
    return user.role.permissions.map((p: Permission) => p.actions.map((action: string) => `${p.group}#${action}`)).flat();
}

export default AllowedAccess;
