// 1. Import the utilities
import {createBreakpoints} from "@chakra-ui/theme-tools";
import {ProductTypes} from "../../common/constants";
// 2. Update the breakpoints as key-value pairs
export const breakpoints: any = createBreakpoints({
    sm: "20em", // 320px / 16px = 20em
    md: "48em", // 768px / 16px = 48em
    lg: "60em", // 960px / 16px = 60em
    xl: "63.75em", // 1020px / 16px = 63.75em
    "2xl": "84em", // 1312px / 16px = 84em
});

export const generalView = {
    home: {width: 1280, height: 800},
    logo: {width: "306px", height: "33px"},
    youtube: {size: "1.6rem", color: "gray.200", linkId: "EdcyHNP5TFg"}
};

export const generalColor = {
    primary: "#2EA3F8",
    primaryGrayLight: "#F3F4F6",
    primaryHeading: "#1F2937",
    primaryText: "#4B5563",
}


export const imageSizes = {
    original: {width: 220, height: 220},
    subgeneral: {width: 300, height: 190},
    general: {width: 300, height: 190},
    cart: {width: 500, height: 500},
    cardPreview: {width: 80, height: 80},
    another: {width: 200, height: 125},
    small: {width: 50, height: 50},
    medium: {width: 75, height: 75},
    large: {width: 150, height: 150},
    additional: {width: 141, height: 138},
};

export const generalBox = {
    subcategory: {width: 302, height: 273, pT: 5, pB: 10},
    category: {width: 302, height: 302, pT: 0, heightTitle: 110, pB: 10},
    additional: {width: 628, height: 117, pT: 5, heightTitle: 110, pB: 0},
    another: {width: 628, height: 200, pT: 2, pB: 5},
    product: {width: 302, height: 194, pT: 0, pB: 5},
    common: {radius: 9, border: "1px solid #D1D5DB"}
};

export const editFilterProduct = {
    home: {types: [ProductTypes.CATEGORY, ProductTypes.SUBCATEGORY, ProductTypes.ANOTHER, ProductTypes.ADDITIONAL, ProductTypes.NEED_MORE_PRODUCTS]},
    product: {types: [ProductTypes.PRODUCT, ProductTypes.SUBCATEGORY, ProductTypes.CUSTOM, ProductTypes.OPTIONS, ProductTypes.REORDER, ProductTypes.NEED_MORE_PRODUCTS]}
};