import React, {FC} from 'react';
import {AspectRatio, Box, Text} from '@chakra-ui/react';
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {useDispatch} from "react-redux";
import {setBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";

const AboutUs: FC<{ links: Array<BreadcrumbsData> }> = (props) => {
    const dispatch = useDispatch()
    setBreadcrumbsLinks(dispatch, props.links)
    return (
        <>
            <Box fontWeight={"sm"} maxWidth={"630px"}>
                <Text fontSize={"24px"} mb={"16px"} fontWeight={"500"}>About Us</Text>

                <Text mb={"16px"}>We started with the
                    Invention of our Patented Easy Installation Temporary Tag Pouch
                    and Paper. We help dealerships stay compliant with the DMV and law enforcement agencies all across
                    the
                    country!</Text>
                <Text mb={"16px"}>Then grew into a Manufacturing Plant with a Design Center in it for license Plate
                    Frames,
                    Inserts and
                    other products.</Text>
                <Text mb={"16px"}>Now we work one on one with our dealerships to enhance their design and brand,
                    manufacture
                    it and ship
                    their orders the very same day.</Text>
                <Text mb={"16px"}>We have done research in cost efficient and Eco-Friendly processes.</Text>
                <Text mb={"16px"}>In doing so, we have become Better, Faster and Less Expensive!</Text>

                <AspectRatio maxW='350px' maxH={"197px"} ratio={1}>
                    <iframe
                        title='naruto'
                        src='https://www.youtube.com/embed/dDDI18lM4eQ'
                        allowFullScreen
                    />
                </AspectRatio>
            </Box>
        </>
    );
}

export default AboutUs;
