import React from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {Product} from "../../../shared/entities/Product/Product";
import {CardBody} from "../../../components/Card/CardBody";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import EditButton from "../../../components/Buttons/EditButton";
import CustomLink from "../../../components/Link/CustomLink";
import {productEditPermission} from "../../permissions";
import AllowedAccess from "../../../features/Permission";
import {hasPermission} from "../../../features/Permission/AllowedAccess";

type NewProductComponentProps = {
    product: Product;
    height?: string;
    isMobile?: boolean;
};

const NeedMoreProduct: React.FC<NewProductComponentProps> = ({product, height, isMobile = false}) => {
    return (
        <Flex height={height}>
            <AllowedAccess permissionKey={productEditPermission} localPermission={!isMobile}>
                <DragAndDropBox item={product} width={"5%"} orderByZone={true}/>
            </AllowedAccess>
            <CardBody border={"unset"} width={(hasPermission(productEditPermission)) ? "95%" : "100%"}>
                <DragAndDropBox item={product} isDraggable={hasPermission(productEditPermission)}>
                    <EditButton item={product}/>
                    <Flex height={"70%"} alignItems="center">
                        <CustomLink href={`${product.url}`}>
                            <Flex backgroundColor="primary.gray.light"
                                  alignItems="center"
                                  justifyContent="center"
                                  mi="100%"
                                  width={"100%"}
                                  height="80px"
                                  cursor={"pointer"}
                                  border="1px" borderColor={"gray.200"} borderRadius={"md"}>
                                <Box textAlign={"center"} w={"80%"}>
                                    <Text fontSize={16} fontWeight={"md"}>
                                        {product.name}
                                    </Text>
                                </Box>
                            </Flex>
                        </CustomLink>
                    </Flex>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};

export default NeedMoreProduct;