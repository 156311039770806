import {Box, Checkbox, Flex, Grid, Text, useToast} from "@chakra-ui/react";
import {Form, Formik} from "formik"
import {FormControl} from "formik-chakra-ui";
import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router"
import {FormButtonsRow} from "../../../components/Buttons/FormButtonsRow";
import {SectionCard} from "../../../components/Card/SectionCard";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {ListItemConfig, ListItemRow} from "../../../components/List/ListItem";
import {RoleController} from "../../../controllers/RoleController";
import {RoleValidator} from "../../../controllers/Validation/RoleValidator";
import {setBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {ModalService} from "../../../service/Modal/ModalService";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {PreventNavigationModal} from "../../../shared/utilities/PreventNavigationModal";
import {BoxRowStyleConfig, BoxStyleConfig, FormStyleConfig} from "../FormStyleConfigs";
import {Permission} from "../../../shared/entities/Role/Permission";
import {RoleDownIcon, RoleUpIcon, TrashItem} from "../../../components/Inputs/Icons/Icons";
import {Icon} from "@chakra-ui/icons";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {MainButton} from "../../../components/Buttons/MainButton";
import {
    roleCreatePermission,
    roleEditPermission,
    roleRemovePermission
} from "../../permissions";
import AllowedAccess from "../../../features/Permission";
import {hasPermission} from "../../../features/Permission/AllowedAccess";
import {Role} from "../../../shared/entities/Role/Role";

/**
 * Форма создания роли
 * @param param0
 * @returns
 */
export const RoleForm: FC<{ edit?: boolean, links: Array<BreadcrumbsData> }> = ({edit, links}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast();
    const roleController = new RoleController(dispatch)
    const [allPrivileges, setAllPrivileges] = useState<Array<Permission>>([]);

    const modalService = new ModalService(dispatch)
    const [initialValues, setInitialValues] = useState<any>([]);
    const [group, setGroup] = useState<any>([]);
    const [countNewRole, setCountNewRole] = useState<number>(0);
    const [showRole, setShowRole] = useState<boolean[]>(Array(initialValues.length).fill(false));
    const [refreshRole, setRefreshRole] = useState(false);


    useEffect(() => {
        setBreadcrumbsLinks(dispatch, links)
        roleController.getRole().then((response => {
                if (response.Some) {
                    response.Some?.roles.sort((a: Role, b: Role) => Number(a.id) - Number(b.id));
                    let roleDtos = response.Some?.roles.map((role: Role) => {
                        return {
                            id: role.id,
                            permissions: role.permissions.reduce((map: Record<string, string[]>, permission) => {
                                const {group, actions} = permission;
                                map[group] = actions;
                                return map;
                            }, {}),
                            name: role.name,
                            defaultRole: role.defaultRole || false
                        }
                    })
                    setInitialValues(roleDtos)
                }
            })
        )
        roleController.getPrivileges().then((res => {
            if (res.Some) {
                setGroup([...new Set(res.Some.map((item: Permission) => item.group))])
                setAllPrivileges(res.Some);
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshRole])

    const toggleRole = (idx: number) => {
        setShowRole(prevShowRole => {
            const updatedShowRole = [...prevShowRole];
            updatedShowRole[idx] = !updatedShowRole[idx];
            return updatedShowRole;
        });
    };
    const handleNewRole = () => {
        setInitialValues([...initialValues, {
            id: -1,
            permissions: [],
            name: "Custom Role1" + countNewRole,
            defaultRole: false
        }]);
        setCountNewRole(countNewRole + 1);
    };
    const handleRemove = (role: any) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                roleController.deleteRole(Number(role.id)).then(() => {
                    setRefreshRole(prevIndex => !prevIndex);
                    // window.location.reload();
                });
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Role will be deleted.",
            context: "action"
        });
    };

    return (
        <SectionCard>
            <Box {...BoxRowStyleConfig} >
                <Breadcrumbs/>
                <AllowedAccess permissionKey={roleCreatePermission}>
                    <MainButton children={"Create role"} marginLeft="auto" left="0px" onClick={() => handleNewRole()}/>
                </AllowedAccess>
            </Box>
            <Formik initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true)
                        roleController.createUpdateRole(values).then(() => {
                            setRefreshRole(prevIndex => !prevIndex);
                            actions.setSubmitting(false);
                            toast({
                                position: "top",
                                title: 'Update roles',
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            })
                        })
                    }}
                    validationSchema={RoleValidator.GetSchema()}
                    enableReinitialize={true}
            >

                {({isSubmitting, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                    <FormControl name={"name"} {...FormStyleConfig}>
                        <Form onSubmit={handleSubmit}>
                            {initialValues?.map((elemRole: any, idxr: number) => {
                                return (
                                    <Flex
                                        pb={(values?.length - 1) === idxr ? 12 : 2}
                                        key={elemRole.name + idxr}>
                                        <Box {...BoxStyleConfig} borderWidth="1px" boxShadow="md" borderRadius={10}
                                             borderColor={"#5A5A5A"}>
                                            <Box {...BoxRowStyleConfig} pb={1} onClick={() => toggleRole(idxr)} cursor={"pointer"}>
                                                <Text pl={4} fontSize={"18px"} mt={"4px"}>{elemRole.name} </Text>
                                                <Flex spacing="15px" ml={"auto"}>
                                                    <AllowedAccess permissionKey={roleRemovePermission}
                                                                   localPermission={!elemRole.defaultRole}>
                                                        <Icon ml={"auto"} mr={"20px"} mt={"8px"}
                                                              as={TrashItem}
                                                              fontSize={"20px"}
                                                              color="grey.500"
                                                              cursor={"pointer"}
                                                              onClick={() => handleRemove(elemRole)}
                                                        />
                                                    </AllowedAccess>
                                                    <Icon mr={"20px"} mt={"8px"}
                                                          as={showRole[idxr] ? RoleUpIcon : RoleDownIcon}
                                                          fontSize={"20px"}
                                                          color="grey.500"
                                                          cursor={"pointer"}
                                                    />
                                                </Flex>
                                            </Box>
                                            {showRole[idxr] && (
                                                <Box {...BoxRowStyleConfig} mt={"20px"}>
                                                    <Box p={4} pt={0}>
                                                        <AllowedAccess permissionKey={roleEditPermission}>
                                                            <InputControlStyled name={`[${idxr}].name`}
                                                                                onChange={
                                                                                    (ev: any) => setFieldValue(`[${idxr}].name`, ev.target.value, true)
                                                                                }
                                                            />
                                                        </AllowedAccess>
                                                    </Box>
                                                    <Box flexDir={"column"} width="100%" p={4} pt={0}>
                                                        {group?.sort((a: any, b: any) => a.localeCompare(b)).map((elem: any, idxg: number) => {
                                                            const ItemConfig: Array<ListItemConfig> = [
                                                                {
                                                                    content: <Text
                                                                        fontWeight={"600"}>{elem}</Text>,
                                                                    isLink: false,
                                                                    style: {
                                                                        flexBasis: "30%",
                                                                        justifyContent: "flex-start"
                                                                    }
                                                                },
                                                                {
                                                                    content: <FormControl name={elem + "name"}
                                                                                          key={elem + idxg}>
                                                                        <Grid templateColumns="repeat(4, 1fr)" gap={0}>
                                                                            {allPrivileges
                                                                                .find((permission) => permission.group === elem)?.actions
                                                                                .map((item: any, idxp: number) => {
                                                                                    const isChecked = values[idxr].permissions[elem]?.includes(item) || false;
                                                                                    return <Box {...BoxRowStyleConfig}
                                                                                                key={item + idxr + idxp + idxg}>
                                                                                        <Checkbox
                                                                                            key={item + " key" + idxp}
                                                                                            pr={1}
                                                                                            pt={2}
                                                                                            colorScheme={"gray"}
                                                                                            isChecked={isChecked}
                                                                                            isDisabled={!hasPermission(roleEditPermission) && !hasPermission(roleCreatePermission)}
                                                                                            id={`[${idxr}].permissions[${elem}]`}
                                                                                            name={`[${idxr}].permissions[${elem}]`}
                                                                                            value={item}
                                                                                            label={item}
                                                                                            onChange={(e: any) => {
                                                                                                const updatedPermissions = {...values[idxr].permissions};
                                                                                                console.log("updatedPermissions", updatedPermissions, elem, item, e.target.checked)
                                                                                                if (e.target.checked) {
                                                                                                    // Если ключа нет, создаем новый массив с `item`
                                                                                                    if (!updatedPermissions[elem]) {
                                                                                                        updatedPermissions[elem] = [item];
                                                                                                    } else {
                                                                                                        // Добавляем действие в существующий массив
                                                                                                        updatedPermissions[elem].push(item);
                                                                                                    }
                                                                                                } else {
                                                                                                    // Удаляем действие из массива
                                                                                                    updatedPermissions[elem] = updatedPermissions[elem].filter(
                                                                                                        (action: string) => action !== item
                                                                                                    );

                                                                                                    // Если массив действий пуст, удаляем ключ
                                                                                                    if (updatedPermissions[elem].length === 0) {
                                                                                                        delete updatedPermissions[elem];
                                                                                                    }
                                                                                                }

                                                                                                // Обновляем значение в Formik
                                                                                                setFieldValue(`[${idxr}].permissions`, updatedPermissions);
                                                                                            }}
                                                                                        />
                                                                                        <Text>{item}</Text>
                                                                                    </Box>
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </FormControl>
                                                                    ,
                                                                    isLink: false,
                                                                    style: {
                                                                        flexBasis: "70%",
                                                                    }
                                                                }
                                                            ]
                                                            return <ListItemRow style={{
                                                                justifyContent: "flex-start",
                                                                // border: "1px",
                                                                mb: "1px"
                                                            }} key={"listitemrow" + idxr + idxg} list={ItemConfig}/>
                                                        })}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Flex>)
                            })
                            }
                            <Box>
                                <FormButtonsRow

                                    config={{
                                        submitButton: {
                                            disabled: !dirty && isValid,
                                            text: 'Save',
                                            type: "submit",
                                            isSubmitting: isSubmitting
                                        },
                                        cancelButton: {
                                            text: 'Cancel',
                                            cancelFunc: () => PreventNavigationModal(dispatch, () => navigate(-1))
                                        }
                                    }}/>
                            </Box>
                        </Form>
                    </FormControl>
                )}
            </Formik>
        </SectionCard>
    )
}
export default RoleForm;