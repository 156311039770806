import React, {FC, useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Flex,
    useDisclosure, useToast, Input, InputGroup, InputRightElement,
} from "@chakra-ui/react";
import {MainButton} from "../../components/Buttons/MainButton";
import {useAppSelector} from "../../redux/Store";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import {UserController} from "../../controllers/UserController";


interface SetPasswordModalProps {
}

const SetPasswordModal: FC<SetPasswordModalProps> = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const event = useAppSelector(state => state.ModalReducer.event);
    const [commonContent, setCommonContent] = useState<number>(-1);
    const [value, setValue] = useState<string>("")
    const [showNewPassword, setShowNewPassword] = useState(false);
    const dispatch = useDispatch();
    const modalService = new ModalService(dispatch);
    const userController = new UserController(dispatch);

    const toast = useToast()


    useEffect(() => {
        if (event && event.selModel === SelectModal.SET_PASSWORD) {
            setCommonContent(event.selCommonContent)
            onOpen()
        } else {
            onClose()
        }
    }, [event]);

    const handleSaveClick = () => {
        if (commonContent) {
            userController.setNewPassword({id: commonContent, password: value}).then(res => {
                toast({
                    position: "top",
                    title: res.None ? 'No set password for user id: ' + commonContent : 'Set password for user id: ' + commonContent,
                    status: res.None ? 'error' : 'success',
                    duration: 5000,
                    isClosable: true,
                })
            });
        }
        setValue("");
        modalService.resetModal();
        onClose();
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay/>
            <ModalContent>
                <ModalCloseButton/>
                <ModalBody>
                    <ModalHeader>New Password</ModalHeader>
                    <InputGroup>
                        <Input type={showNewPassword ? "text" : "password"} value={value}
                               onChange={(ev: any) => setValue(ev.target.value)}
                        />
                        <InputRightElement>
                            {showNewPassword ? (
                                <ViewOffIcon cursor="pointer" onClick={() => setShowNewPassword(false)}/>
                            ) : (
                                <ViewIcon cursor="pointer" onClick={() => setShowNewPassword(true)}/>
                            )}
                        </InputRightElement>
                    </InputGroup>
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent="flex-end" width="100%">
                        <MainButton ml={"1rem"} onClick={handleSaveClick}>Save</MainButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SetPasswordModal;
