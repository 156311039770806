import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import "./index.scss"
import {customTheme} from './theme/theme';
import {Provider} from 'react-redux';
import {store} from './redux/Store';
import {AuthProvider} from "./hooks/AuthHook"

/**
 * Index.
 *
 * App's root element
 * Index for frontend applications
 * is what Main method or Main function in any other languages or technologies
 * DO NOT write ANY logic in this component
 * The only thing Index file should do is inject render App component,
 * apply router and pass shared state to all child elements
 *
 * @see Provider
 * Component, providing shared access to redux state
 * More on Provider:
 * @link https://react-redux.js.org/api/provider
 *
 * @see ChakraProvider
 * Component, providing shared access to Chakra styles config
 * More on ChakraProvider
 * @link https://chakra-ui.com/docs/getting-started
 *
 * @see BrowserRouter
 * Main application router provider
 * Synchronizes UI with current URL
 * More on BrowserRouter:
 * @link https://v5.reactrouter.com/web/api/BrowserRouter
 *
 *
 * @see App
 * App's main starting point
 *
 */

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={customTheme}>
            <BrowserRouter>
                <AuthProvider isAuth={false}>
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </AuthProvider>
            </BrowserRouter>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
