import React, {useEffect, useState} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {Product} from '../../shared/entities/Product/Product';
import {MainButton} from "../../components/Buttons/MainButton";
import {useLocation, useNavigate} from "react-router";
import EditButton from "../../components/Buttons/EditButton";
import {ProductTypes} from "../../common/constants";
import DragAndDropBox from "../../components/DragAndDrop/DragAndDropBox";
import QuantityInput from "../../components/Inputs/QuantityInput";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import {useAppSelector} from "../../redux/Store";
import DiscountComponent from "./Discount/DiscountComponent";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import ImageCustom from "../../components/Image/ImageCustom";
import {WebPUtils} from "../../shared/utilities/WebPUtils";
import CustomLink from "../../components/Link/CustomLink";
import {productEditPermission} from "../permissions";
import AllowedAccess from "../../features/Permission";
import {hasPermission} from "../../features/Permission/AllowedAccess";

type ProductItemProps = {
    product: Product;
    singleHide?: boolean;
    onAddToCart?: (product: Product, quantity: number) => void;
    height?: string;
    width?: string;
    isMobile?: boolean;
};

const ProductItem: React.FC<ProductItemProps> = ({
                                                     product, onAddToCart,
                                                     singleHide = true,
                                                     height,
                                                     width = "100%",
                                                     isMobile = false
                                                 }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const calculatedPrice = ProductCostCalculateUtils.calculatePrice(product, authDealership ? authDealership : undefined);
    const modalService = new ModalService(dispatch);
    const [reset, setReset] = useState<boolean>(false);
    const query = new URLSearchParams(useLocation().search).get('s');
    const highlightText = (text: string, words: string[]) => {
        const regex = new RegExp(`(${words.join('|')})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) =>
            regex.test(part) ? (
                <span key={index} style={{backgroundColor: 'yellow'}}>
                    {part}
                </span>
            ) : (
                part
            )
        );
    };

    function normalizeName(name: any): string {
        // Приводим строку к нижнему регистру и удаляем пробелы с краев
        if (!name) return "";
        const normalized = name.toLowerCase().trim();

        // Заменяем дефисы на пробелы
        const noDashes = normalized.replace(/-/g, " ");

        // Удаляем все символы, кроме латинских букв, цифр и пробелов
        const cleaned = noDashes.replace(/[^a-z0-9 ]/g, "");

        // Убираем множественные пробелы
        return cleaned.replace(/\s+/g, " ");
    }

    useEffect(() => {
        setTotal(Number((calculatedPrice * quantity).toFixed(4)));
    }, [product.price, quantity]);

    function getLabel(product: Product): string {
        switch (product.type) {
            case ProductTypes.CUSTOM:
                return "ADD-CUSTOM"
            case ProductTypes.REORDER:
                return "RE-ORDER"
            case ProductTypes.PRODUCT:
                return "Add to Cart"
            default:
                return "UNDEF"
        }
    }

    return (
        <Flex maxWidth={width}>
            <AllowedAccess permissionKey={productEditPermission} localPermission={!isMobile}>
                <DragAndDropBox item={product} width={"5%"} orderByZone={true} height={`${height}px`}/>
            </AllowedAccess>
            <DragAndDropBox item={product} isDraggable={hasPermission(productEditPermission)}>
                {singleHide && (
                    <Box border="1px" borderColor={"gray.200"} borderRadius={"md"}>
                        <EditButton riseLevelProduct={hasPermission(productEditPermission)} item={product}/>
                        <ImageCustom elem={product} sizeInPercent={isMobile ? 75 : 100} showOnHover={false}
                                     isMobile={isMobile}
                                     sizeBackend={"COMMON"}
                                     hideArrows={false}/>
                    </Box>
                )}
                <CustomLink href={WebPUtils.getProductLink(product)}>
                    <Text fontWeight={"md"} fontSize={"14px"} pt={4}>
                        {query?.trim()
                            ? highlightText(product.name.replace("(# ", "(#"), normalizeName(query).split(" "))
                            : product.name.replace("(# ", "(#")}
                    </Text>
                </CustomLink>
                {product.description &&
                    <Text color="primary.text" fontWeight={"sm"} pt={1} fontSize={"14px"}>
                        {query?.trim()
                            ? highlightText(product.description.replace(/\s*\n\s*/g, ' ').trim(), normalizeName(query).split(" "))
                            : product.description
                        }
                    </Text>
                }
                <Box display="flex" flexDir="row">
                    {ProductTypes.CUSTOM === product.type &&
                        <>
                            <Text fontSize={"14px"}>
                                {'This product is custom and will be billed on a separate invoice.'}
                            </Text>
                        </>
                    }
                    {ProductTypes.REORDER === product.type &&
                        <>
                            <Text fontSize={"14px"}>
                                {'This product is custom and will be billed on a separate invoice.'}
                            </Text>
                        </>
                    }
                </Box>
                {ProductTypes.PRODUCT === product.type &&
                    <Box pt={1}>
                        <DiscountComponent elem={product} textAlign={"left"} showDiscount={true}
                                           selectedDealer={authDealership ? authDealership : undefined}/>
                    </Box>
                }
                <Flex alignItems="center" pt={1}>
                    {ProductTypes.OPTIONS !== product.type && ProductTypes.SUBCATEGORY !== product.type &&
                        (
                            <Flex width={isMobile ? "100%" : "unset"} flexDir={isMobile ? "column" : "row"}>
                                <Box pb={isMobile ? 2 : "unset"} pr={!isMobile ? 1 : "unset"}>
                                    <QuantityInput item={product} reset={reset} width={isMobile ? "100%" : 97}
                                                   height={isMobile ? "40px" : "28px"}
                                                   isMobile={isMobile}
                                                   onQuantityChange={(updatedItem) => {
                                                       setQuantity(updatedItem.quantity)
                                                       setReset(false);
                                                   }}/>
                                </Box>


                                <MainButton
                                    isDisabled={quantity === 0}
                                    height={isMobile ? "40px" : "28px"}
                                    onClick={() => {
                                        modalService.setModal({
                                            selModel: SelectModal.ADD_PRODUCT,
                                            product: product,
                                            quantity: quantity
                                        })
                                        setReset(true);
                                    }}>
                                    {getLabel(product)}
                                </MainButton>
                            </Flex>
                        )
                    }

                    {ProductTypes.OPTIONS === product.type &&
                        <MainButton
                            width={isMobile ? "100%" : "unset"}
                            height={isMobile ? "40px" : "28px"}
                            onClick={() => navigate(WebPUtils.getProductLink(product))}>
                            OPTIONS
                        </MainButton>
                    }
                </Flex>
                {(ProductTypes.OPTIONS !== product.type &&
                        ProductTypes.SUBCATEGORY !== product.type &&
                        ProductTypes.CUSTOM !== product.type &&
                        ProductTypes.REORDER !== product.type
                    ) &&
                    <Text fontSize="14px" color={"primary.text"} pt={1}>
                        <span style={{fontWeight: "400", paddingRight: 5}}> {"TOTAL"}</span>
                        <span style={{fontWeight: "600"}}>{`$${total}`}</span>
                    </Text>
                }
            </DragAndDropBox>
        </Flex>
    );
};

export default ProductItem;
