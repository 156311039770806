import React from 'react';
import {ChakraProps, Flex} from '@chakra-ui/react';
import {
    ArrowTurnLeftUp20RegularIcon,
    EditItem, ArchiveBoxArrowDown,
    TrashItem, ArchiveBoxArrowUp
} from "../Inputs/Icons/Icons";
import {Product, ProductChangeParent, ProductSetArchive} from "../../shared/entities/Product/Product";
import {ProductController} from "../../controllers/ProductController";
import {useDispatch} from "react-redux";
import {URLPaths} from "../../config/application/URLPaths";
import {useLocation, useNavigate} from "react-router";
import {ModalService} from "../../service/Modal/ModalService";
import {ActionTypes} from "../../redux/CurrentState/DragAndDropReducer";
import IconWithTooltip from "../Icon/IconWithTooltip";
import {productEditPermission, productRemovePermission} from "../../views/permissions";
import AllowedAccess from "../../features/Permission";


interface EditButtonsProps extends ChakraProps {
    showEdit?: boolean;
    showRemove?: boolean;
    fromArchive?: boolean;
    toArchive?: boolean;
    riseLevelProduct?: boolean;
    item: Product;
}

const EditButton: React.FC<EditButtonsProps> = ({
                                                    showEdit = true,
                                                    showRemove = true,
                                                    fromArchive = true,
                                                    toArchive = true,
                                                    riseLevelProduct = false,
                                                    item,
                                                    ...props
                                                }) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const containsArchive = location.pathname.includes("archive");

    const productController = new ProductController(dispatch);
    const navigate = useNavigate();
    const modalService = new ModalService(dispatch);

    const onEditClick = () => {
        navigate(`${URLPaths.PRODUCTS_EDIT.link}${item.id}`)
    };

    const onRemoveClick = () => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                productController.deleteProduct(Number(item.id)).then(() => {
                    dispatch({type: ActionTypes.CHANGE_PRODUCT});
                });
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Instead of deleting, you can archive the product.Are you sure you want to delete?",
            context: "action"
        });
    };

    const handleSetArchiveProduct = (flag: boolean) => {
        const productSetArchive: ProductSetArchive = {
            id: item.id,
            flag: flag
        };

        productController.setArchiveProduct(productSetArchive).then((res) => {
            dispatch({type: ActionTypes.CHANGE_PRODUCT});
        });
    };

    const handleRiseLevelProduct = () => {
        const productChangeParent: ProductChangeParent = {
            id: Number(item.id),
            parent: -1
        };
        productController.changeParentProduct(productChangeParent).then(() => {
            dispatch({type: ActionTypes.CHANGE_PRODUCT});
        });
    };
    return (
        <Flex spacing="15px" key={item.id} {...props}>
            <AllowedAccess permissionKey={productEditPermission} localPermission={showEdit}>
                <IconWithTooltip icon={EditItem} tooltipMessage={'Edit'} onClick={onEditClick}/>
            </AllowedAccess>
            <AllowedAccess permissionKey={productRemovePermission} localPermission={showRemove}>
                <IconWithTooltip icon={TrashItem} tooltipMessage={'Remove'} onClick={onRemoveClick}/>
            </AllowedAccess>
            <AllowedAccess permissionKey={productEditPermission} localPermission={containsArchive && fromArchive}>
                <IconWithTooltip icon={ArchiveBoxArrowUp} tooltipMessage={'back from archive'}
                                 onClick={() => handleSetArchiveProduct(false)}/>
            </AllowedAccess>
            <AllowedAccess permissionKey={productEditPermission} localPermission={!containsArchive && toArchive}>
                <IconWithTooltip icon={ArchiveBoxArrowDown} tooltipMessage={'to archive'}
                                 onClick={() => handleSetArchiveProduct(true)}/>
            </AllowedAccess>
            <AllowedAccess permissionKey={productEditPermission} localPermission={riseLevelProduct}>
                <IconWithTooltip icon={ArrowTurnLeftUp20RegularIcon} tooltipMessage={'Up on Top Level'}
                                 onClick={() => handleRiseLevelProduct()}/>
            </AllowedAccess>
        </Flex>
    );
};

export default EditButton;