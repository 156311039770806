import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {Box, Checkbox, Flex, Text, useBreakpointValue, useToast} from "@chakra-ui/react";
import {useAppSelector} from "../../redux/Store";
import {Product} from "../../shared/entities/Product/Product";
import {ListProductItemConfig, ListProductItemRow} from "../../components/List/ListProductItem";
import {Loader} from "../../components/Loader/Loader";
import {MainButton} from "../../components/Buttons/MainButton";
import {BoxStyleConfig} from "../Forms/FormStyleConfigs";
import {setBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import ImageCustom from "../../components/Image/ImageCustom";
import QuantityInput from "../../components/Inputs/QuantityInput";
import {ProductTypes} from "../../common/constants";
import DiscountComponent from "../Products/Discount/DiscountComponent";
import {ProductController} from "../../controllers/ProductController";
import {CartService} from "../../service/Cart/CartService";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {OrderProductController} from "../../controllers/OrderProductController";
import {UserController} from "../../controllers/UserController";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";

export const OrderBasket: FC<{ account?: boolean, links?: Array<BreadcrumbsData> }> = ({account = false, links}) => {
    const dispatch = useDispatch();

    const toast = useToast()
    const [isLoading, setIsLoading] = useState(true)
    const orderProductController = new OrderProductController(dispatch);
    const productController = new ProductController(dispatch);
    const {dealerId} = useParams();
    const userController = new UserController(dispatch)
    const cartService = new CartService(dispatch)

    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [updatedProductList, setProductList] = useState<Array<Product>>([]);

    const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const selDealership = useAppSelector(state => state.UserReducer.dealership);
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})
    if (links) {
        setBreadcrumbsLinks(dispatch, links);
    }



    useEffect(() => {
        setIsLoading(true);
        let id = dealerId ? dealerId : authDealership?.id
        if (id) {
            userController.getDealership({id: Number(id)});
            orderProductController.getAllProductsFromDealerOrders(Number(id)).then((orderProducts) => {
                setIsLoading(false);
                if (orderProducts.None) {
                    toast({
                        position: "top",
                        title: 'Error receiving order,' + orderProducts?.None?.getErrorDescription() + ', try logging in',
                        status: 'error',
                        duration: 8000,
                        isClosable: true,
                    })
                } else {
                    const newUpdatedProductList = orderProducts.Some.map((elem: any) => {
                        return {
                            ...elem.product,
                            quantity: 0,
                            discount: elem.product.discount,
                            shippingCost: elem.product.shippingCost,
                            taxCost: elem.product.taxCost,
                            price: elem.product.price,
                            finalPrice: elem.product.price,
                        };
                    });
                    setProductList(newUpdatedProductList);
                }
            });
        } else {
            setIsLoading(false);
        }
    }, [dealerId, authDealership]);

    interface ColumnConfig {
        header: string | JSX.Element;
        width?: string;
        isLink?: boolean;
        style?: object;
    }

    const columns: Array<ColumnConfig> = isMobile ? [
        {
            header: <Box>
                <Text>Product</Text>
                <Text>Item price</Text>
            </Box>,
            width: "30%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }, {
            header: <Box>
                <Text>Info</Text>
                <Text>Qty</Text>
            </Box>,
            width: "40%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }, {
            header: <Box>
                <Text>Total price</Text>
            </Box>,
            width: "30%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }
    ] : [
        {
            header: "Product",
            width: "10%",
        },
        {
            header: "Info",
            width: "50%",
        },
        {
            header: "Item price",
            width: "10%",
        },
        {
            header: "Qty",
            width: "10%",
        },
        {
            header: "Total price",
            width: "15%",
        },
        {
            header: "",
            width: "5%",
        }
    ];

    const headers: Array<ListProductItemConfig> = columns.map(
        (col: ColumnConfig) => {
            return {
                content: col.header || "",
                width: col.width || "auto",
                isLink: col.isLink || false,
                style: col.style || {},
            };
        }
    );
    const handleCheckboxChange = (id: number, checked: boolean) => {
        if (checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
    };

    const handleAddedSelectProductToCart = () => {
        setIsLoading(true);
        productController.getProductsIds({ids: selectedIds?.join(",")}).then(productList => {
            if (productList.Some) {
                productList.Some.items.map((product: any) => {
                    const existingProduct = updatedProductList.find((item) => item.id === product.id);
                    if (existingProduct) {
                        product.quantity = existingProduct.quantity;
                    }
                    cartService.addProductToCart(product);
                })
                setIsLoading(false);
            }
        });
    }

    const getList = (productList: Array<Product>) => {
        return productList?.map((elem: Product, idx: number) => {
            const ItemConfig: Array<ListProductItemConfig> = isMobile ? [
                    {
                        content:
                            <Box pb={4} width={"100%"}>
                                <Flex>
                                    <Box minWidth={"25%"}>
                                        <ImageCustom elem={elem} sizeInPercent={100} currentSize={"medium"}
                                                     sizeBackend={"COMMON"}/>
                                    </Box>
                                    <Box flexDir="column" textAlign="left" pl={3} pt={2}>
                                        <Text fontSize={16} fontWeight="md">{elem.name}</Text>
                                        {/*<Text fontWeight="500" color="gray.400"> Product#: {elem.id} </Text>*/}
                                        <Text fontSize={14} color="gray.400">{elem.description}</Text>
                                        {elem.type === ProductTypes.CUSTOM && (
                                            <Text fontWeight="600" fontSize="16px">
                                                {'This product is custom and will be billed and shipped on separate invoice.'}
                                            </Text>
                                        )}
                                        {elem.type === ProductTypes.REORDER && (
                                            <Text fontWeight="600" fontSize="16px">
                                                {'This product will be billed and shipped on separate invoice.'}
                                            </Text>
                                        )}
                                    </Box>
                                </Flex>
                                <Flex alignItems={"center"} pt={2}>
                                    <Flex minWidth={"32%"}>
                                        <DiscountComponent elem={elem} hiddenUOM={true}
                                                           fontWeightPrice={"300"}
                                                           textAlign={"left"}
                                                           selectedDealer={selDealership}
                                        />
                                    </Flex>
                                    <Flex w={"100%"}>
                                        <QuantityInput item={elem}
                                                       isMobile={isMobile}
                                                       width={"50%"}
                                                       onQuantityChange={(updatedItem) => {
                                                           const updatedProduct = cartItems.find((item) => item.id === elem.id);
                                                           setProductList((prevProductList) => {
                                                               return prevProductList.map((item) => {
                                                                   if (item.id === updatedItem.id) {
                                                                       return {...item, quantity: updatedItem.quantity}; // Создаём новый объект с обновлённым quantity
                                                                   }
                                                                   return item;
                                                               });
                                                           });
                                                           if (updatedProduct) {
                                                               updatedProduct.quantity = updatedItem.quantity;
                                                               cartService.addProductToCart(updatedProduct);
                                                           }
                                                       }}/>

                                        <Flex justifyContent={"space-between"} w={"50%"} alignItems={"center"}>
                                            <Text fontSize="16px" fontWeight={"md"}
                                                  pl={3}> {"$" + (elem.price * elem.quantity).toFixed(2)}
                                            </Text>
                                            <Box pr={1}>
                                                <Checkbox marginLeft={"10px"} checked={selectedIds.includes(elem?.id)}
                                                          onChange={(event) => handleCheckboxChange(elem?.id, event.target.checked)}
                                                />
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Box>,
                        isLink: false,
                        width: "100%",
                        justifyContent: "flex-start"
                    }] :
                [
                    {
                        content: <ImageCustom elem={elem} sizeInPercent={100} currentSize={"medium"}
                                              sizeBackend={"COMMON"}/>,
                        isLink: false,
                        width: columns[0]?.width || "auto",
                    },
                    {
                        content:
                            <Flex flexDir="column" textAlign="left">
                                <Text fontSize={16} fontWeight="md">{elem.name}</Text>
                                {/*<Text fontWeight="500" color="gray.400"> Product#: {elem.id} </Text>*/}
                                <Text fontSize={14} color="gray.400">{elem.description}</Text>
                                {elem.type === ProductTypes.CUSTOM && (
                                    <Text fontWeight="600" fontSize="16px">
                                        {'This product is custom and will be billed and shipped on separate invoice.'}
                                    </Text>
                                )}
                                {elem.type === ProductTypes.REORDER && (
                                    <Text fontWeight="600" fontSize="16px">
                                        {'This product will be billed and shipped on separate invoice.'}
                                    </Text>
                                )}
                            </Flex>
                        ,
                        width: columns[1]?.width || "auto",
                    },
                    {
                        content: <DiscountComponent elem={elem} hiddenUOM={true} selectedDealer={selDealership}
                                                    showMarkup={true} showDiscount={true} showTax={true}/>,
                        width: columns[2]?.width || "auto",
                    },
                    {
                        content:
                            <QuantityInput item={elem}
                                           onQuantityChange={(updatedItem) => {
                                               const updatedProduct = cartItems.find((item) => item.id === elem.id);
                                               setProductList((prevProductList) => {
                                                   return prevProductList.map((item) => {
                                                       if (item.id === updatedItem.id) {
                                                           return {...item, quantity: updatedItem.quantity}; // Создаём новый объект с обновлённым quantity
                                                       }
                                                       return item;
                                                   });
                                               });
                                               if (updatedProduct) {
                                                   updatedProduct.quantity = updatedItem.quantity;
                                                   cartService.addProductToCart(updatedProduct);
                                               }
                                           }}/>,
                        width: columns[3]?.width || "auto",
                    },
                    {
                        content: <Text fontSize={14}
                                       userSelect="none"> {"$" + Number((ProductCostCalculateUtils.calculatePrice(elem, selDealership) * elem.quantity).toFixed(2))} </Text>,
                        width: columns[4]?.width || "auto",
                    },
                    {
                        content: <Checkbox marginLeft={"10px"} checked={selectedIds.includes(elem?.id)}
                                           onChange={(event) => handleCheckboxChange(elem?.id, event.target.checked)}/>,
                        width: "auto",
                    }
                ]
            return <ListProductItemRow key={idx} list={ItemConfig} style={{justifyContent: "flex-start"}}/>;
        });
    }

    return (
        <>
            {!account &&
                <Breadcrumbs/>
            }
            {isLoading ? <Loader/> :
                <Box height={"100%"} p={{base: 0, lg: 0}}>
                    <Box {...BoxStyleConfig} pt={2}>
                        <ListProductItemRow list={headers} noBorder={false}
                                            style={{fontSize: 14, justifyContent: "flex-start"}}/>
                        {getList(updatedProductList)}
                        <MainButton ml={{lg: "auto"}} minWidth={"180px"} minH={"40px"} mt={4}
                                    disabled={selectedIds && selectedIds.length === 0}
                                    onClick={() => handleAddedSelectProductToCart()}>
                            {'Add to cart'}
                        </MainButton>
                    </Box>
                </Box>
            }
        </>
    );
}

export default OrderBasket;
