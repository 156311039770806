import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Box, Checkbox, Flex, Input, Text} from '@chakra-ui/react';
import {MainButton} from "../../../components/Buttons/MainButton";
import ImageCustom from "../../../components/Image/ImageCustom";
import {useFormikContext} from "formik";
import {ImageItem, Product} from "../../../shared/entities/Product/Product";
import {ImageService} from "../../../service/ImageService";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";

interface PhotoUploadManagerProps {
    primaryImage: number;
    item: Product;
}

const PhotoUploadManager: React.FC<PhotoUploadManagerProps> = ({item, primaryImage = 0}) => {
    const imageItems = useSelector((state: any) => state.ImageReducer.images);
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [updateMode, setUpdateMode] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>('');
    const imageService = new ImageService(dispatch);
    const {setFieldValue} = useFormikContext();

    const handleIndexChange = (index: number) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        if (item && item.images && item.images.length > 0) {
            const newImages = item.images.map((image, index) => ({
                url: WebPUtils.getImageUrl("common", image.uuid, false),
                file: null,
                uuid: image.uuid,
                index: index
            }));
            const shouldUpdate = newImages.some((newImage) => imageItems && !imageItems.some((existingImage: ImageItem) => existingImage.url === newImage.url));
            if (shouldUpdate) {
                imageService.addImages(newImages);
                setCurrentIndex(0)
                if (imageItems.length > 0) {
                    setCurrentIndex((prevIndex) => prevIndex + newImages.length);
                } else {
                    setCurrentIndex(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    const handleImageUpload = (files: FileList | null) => {

        const newImages = files ? Array.from(files) : [];
        const newImagesWithInfo: ImageItem[] = newImages.map(file => ({
            file: file,
            url: URL.createObjectURL(file),
            uuid: "",
        }));
        if (updateMode) {
            imageService.updateImage(currentIndex, newImagesWithInfo[0]);
            setCurrentIndex(0);
            setUpdateMode(false);
        } else {
            imageService.addImages(newImagesWithInfo);
            setCurrentIndex(imageItems > 0 ?imageItems-1 : 0);
        }
    };

    const handleRemoveImage = (index: number) => {
        console.log("remove index" ,index,currentIndex)
        imageService.removeImage(index);
        setCurrentIndex(index > 0 ?index-1 : 0);
    };

    const handleChooseImage = () => {
        const input = document.getElementById('imageInput') as HTMLInputElement;
        if (input) {
            input.click();
        }
    };

    const handleFileUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileUrl(e.target.value);
    };

    const handleFileUrlUpload = () => {
        console.log("handleFileUrlUpload 1")

        if (!fileUrl || (!fileUrl.startsWith('http://') && !fileUrl.startsWith('https://'))) {
            return;
        }
        const newImage: ImageItem = {
            file: null,
            url: fileUrl,
            uuid: "url",
        };
        console.log("handleFileUrlUpload 2", newImage)
        imageService.addImages([newImage]);
    };
    console.log("imageItems Pre Render ", imageItems)
    return (
        <Box pt={4}>
            <Flex flexDir="row">
                <Text minWidth={'5px'}>
                    {imageItems && imageItems.length > 0 ? `Number: ${imageItems.length} curr: ${currentIndex + 1}` : ''}
                </Text>
                <Checkbox
                    color="gray.400"
                    ml={'10px'}
                    isChecked={primaryImage === currentIndex}
                    children={'Show first'}
                    onChange={(ev: any) =>
                        setFieldValue('primaryImage', ev.target.checked ? currentIndex : primaryImage, true)
                    }
                />
            </Flex>
            <Flex alignItems="center" mb={4} mr={4}>
                <Box w={"40%"}>
                    <ImageCustom
                        elem={item}
                        sizeInPercent={60}
                        isMobile={true}
                        upload={true}
                        sizeBackend={"large"}
                        showOnHover={false}
                        hideArrows={false}
                        parentCurrentIndex={currentIndex}
                        onIndexChange={handleIndexChange}
                    />
                </Box>
                <Flex flexDirection="column" ml={imageItems && imageItems.length > 0 ? '1rem' : 'default'}>
                    <Input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={(e) => handleImageUpload(e.target.files)}
                    />
                    <MainButton mb={'0.2rem'} onClick={() => {
                        handleChooseImage();
                        setUpdateMode(imageItems && imageItems.length > 0);
                    }}>
                        {imageItems && imageItems.length > 0 ? 'Update' : 'Upload'}
                    </MainButton>
                    {imageItems && imageItems.length > 0 && (
                        <>
                            <MainButton mb={'0.2rem'} onClick={() => {
                                handleChooseImage();
                                setUpdateMode(false);
                            }}>
                                Add Image
                            </MainButton>
                            <MainButton onClick={() => handleRemoveImage(currentIndex)} colorScheme="red">
                                Remove
                            </MainButton>
                        </>
                    )}
                </Flex>
            </Flex>
            <Flex flexDirection="row">
                <Input
                    placeholder="Enter file URL"
                    value={fileUrl}
                    onChange={handleFileUrlChange}
                />
                <MainButton ml={2} onClick={handleFileUrlUpload} disabled={!fileUrl} height={"40px"}>
                    Upload URL
                </MainButton>
            </Flex>
        </Box>
    );
};

export default PhotoUploadManager;
