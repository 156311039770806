import {Box, Text, Flex, useToast} from "@chakra-ui/react";
import React, {FC, Key, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {Loader} from "../../components/Loader/Loader";
import {Paginator} from "../../components/Paignator/Paginator";
import {UserController} from "../../controllers/UserController";
import {ListProductItemConfig, ListProductItemRow} from "../../components/List/ListProductItem";
import FilterSortHeader from "../../components/Buttons/FilterSortHeader";
import {URLPaths} from "../../config/application/URLPaths";
import {ModalService} from "../../service/Modal/ModalService";
import {FilterConfig, FilterInterceptor} from "../../shared/utilities/Filters/FilterInterceptor";
import {FieldType} from "../../common/constants";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {setBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {Dealership} from "../../shared/entities/Users/Dealership";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {
    FaAngleDown,
    FiBriefcase, FiEye, FiItalic, FiKey,
    FiPercent,
    FiRotateCcw,
    FiTrash,
    FiTruck,
    FiUserCheck, FiUserPlus
} from "react-icons/all";
import {FiCheck, FiEdit} from "react-icons/fi";
import {FilterRequest, User} from "../../shared/entities/Users/User";
import SetPasswordModal from "../Modal/SetPasswordModal";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {Company} from "../../shared/entities/Company/Company";
import {AddUserModal} from "../Modal/AddUserModal";
import {BoxRowStyleConfig} from "../Forms/FormStyleConfigs";
import NavBarLinkButton from "../../components/Link/NavBarLinkButton";
import AddedProductVendor from "../Modal/AddedProductVendor";
import {
    clientCreatePermission,
    clientEditPermission,
    clientRemovePermission,
    subscribeExcludeViewPermission
} from "../permissions";
import AllowedAccess, {hasPermission} from "../../features/Permission/AllowedAccess";

/**
 * Clients.
 *
 * This component represents users registry
 * Serves a purpose to be a container for an
 * actual users list and a paginator
 */
export const Clients: FC<{ links: Array<BreadcrumbsData> }> = ({links}) => {
    const navigate = useNavigate()
    const toast = useToast()
    const dispatch = useDispatch()
    const modalService = new ModalService(dispatch)
    const [isLoading, setIsLoading] = useState(true)
    const [listAll, setListAll] = useState<any>({companies: [], count: 0})
    const userController = new UserController(dispatch)

    setBreadcrumbsLinks(dispatch, links);

    const [currentPaginatorPosition, setCurrentPaginatorPosition] = useState(1)
    const [previewPaginatorPosition, setPreviewPaginatorPosition] = useState(1)
    const [filterInterceptor] = useState(new FilterInterceptor([]));
    const [filterConfig, setFilterConfig] = useState<any>()
    const [refreshClients, setRefreshClients] = useState(false);
    const [expandedUsersRows, setExpandedUsersRows] = useState<number[]>([]);
    const [expandedCompaniesRows, setExpandedCompaniesRows] = useState<number[]>([]);

    const handleRowClick = (index: number, setExpandedRows: React.Dispatch<React.SetStateAction<number[]>>) => {
        if (setExpandedRows === setExpandedUsersRows) {
            if (expandedUsersRows.includes(index)) {
                setExpandedRows(expandedUsersRows.filter((rowIndex) => rowIndex !== index));
            } else {
                setExpandedRows([...expandedUsersRows, index]);
            }
        } else if (setExpandedRows === setExpandedCompaniesRows) {
            if (expandedCompaniesRows.includes(index)) {
                setExpandedRows(expandedCompaniesRows.filter((rowIndex) => rowIndex !== index));
            } else {
                setExpandedRows([...expandedCompaniesRows, index]);
            }
        }
    };

    const handleRowUsersClick = (index: number) => {
        handleRowClick(index, setExpandedUsersRows);
    };
    const handleRefreshComponent = () => {
        setRefreshClients(prevIndex => !prevIndex);
    };
    const handleRowCompanyClick = (index: number) => {
        handleRowClick(index, setExpandedCompaniesRows);
    };

    const handleCompanyRemove = (item: any) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                userController.deleteCompany(Number(item.id)).then(() => {
                    setRefreshClients(prevIndex => !prevIndex);
                });
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "When deleting a company, all order history for that company will also be deleted.",
            context: "action"
        });
    };

    const handleRemove = (item: any) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                userController.deleteUser(Number(item.id)).then(() => {
                    setRefreshClients(prevIndex => !prevIndex);
                });
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "When deleting a user, all order history for that user will also be deleted.",
            context: "action"
        });
    };
    const handlePageChange = (newPage: number) => {
        setPreviewPaginatorPosition(currentPaginatorPosition);
        setCurrentPaginatorPosition(newPage);
    };

    useEffect(() => {
        setIsLoading(true)
        const reqConfig = {
            params: {
                page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : 0,
                size: 20
            }
        }
        setPreviewPaginatorPosition(currentPaginatorPosition);
        const filterRequest: FilterRequest = {
            user: {name: "", email: ""},
            dealer: {name: "", email: ""},
            company: {name: "", email: ""},
        };

        filterInterceptor.getFilterMap().forEach((filter, key) => {
            if (filter !== undefined) {
                if (key === "company") {
                    filterRequest.company.name = filter.value.trim()
                }
                if (key === "userName") {
                    filterRequest.user.name = filter.value.trim()
                }
                if (key === "userEmail") {
                    filterRequest.user.email = filter.value.trim()
                }
                if (key === "dealershipName") {
                    filterRequest.dealer.name = filter.value.trim()
                }
                if (key === "dealershipEmail") {
                    filterRequest.dealer.email = filter.value.trim()
                }
            }
        })
        // userController.getAll(reqConfig?.params, filterRequest).then(res => {
        //     setIsLoading(false)
        //     setListAll(res.Some)
        // })
        userController.getCompanyAll(reqConfig?.params, filterRequest).then(res => {
            setIsLoading(false)
            setListAll(res.Some)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterConfig, currentPaginatorPosition, refreshClients])

    interface ColumnConfig {
        id?: number;
        header: string;
        width?: string;
        isLink?: boolean;
        style?: object;
        type: FieldType;
        options?: any;
        filterField?: string;
    }


    const handleSortUpClick = () => {
    };
    const handleSortDownClick = () => {
    };

    const columnsAll: Array<ColumnConfig> = [
        {
            header: "COMPANY",
            filterField: "company",
            isLink: false,
            width: "15%",
            type: FieldType.Input
        },
        {
            header: "USER NAME",
            filterField: "userName",
            isLink: false,
            width: "15%",
            type: FieldType.Input
        },
        {
            header: "USER EMAIL",
            filterField: "userEmail",
            isLink: false,
            width: "20%",
            type: FieldType.Input
        },
        {
            header: "DEALERSHIP NAME",
            filterField: "dealershipName",
            isLink: false,
            width: "20%",
            type: FieldType.Input
        },
        {
            header: "DEALERSHIP EMAIL",
            filterField: "dealershipEmail",
            isLink: false,
            width: "20%",
            type: FieldType.Input
        },
        {header: "ACTIONS", isLink: false, width: "10%", type: FieldType.Input},
    ];

    const [isFilterInputVisible, setIsFilterInputVisible] = useState(columnsAll.map(() => false));
    const [isFilterOpen, setIsFilterOpen] = useState(columnsAll.map(() => false));
    const handleFilterClick = (column: ColumnConfig, sel: boolean) => {
        if (typeof column.id === 'number') {
            updateFilterState(column.id, sel);
        }
    };

    const updateFilterState = (columnId: number, isOpen: boolean) => {
        setIsFilterOpen(prevIsFilterOpen => {
            const newIsFilterOpen = [...prevIsFilterOpen];
            newIsFilterOpen[columnId] = isOpen;
            return newIsFilterOpen;
        });
    };

    const updateImageFilterState = (columnId: number, isOpen: boolean) => {
        setIsFilterInputVisible(prevIsFilterOpen => {
            const newIsFilterOpen = [...prevIsFilterOpen];
            newIsFilterOpen[columnId] = isOpen;
            return newIsFilterOpen;
        });
    };

    const setNewFilterValue = (val: any, modal: boolean) => {
        if (val && typeof val === 'object') {
            const columnFilterField = Object.keys(val)[0];
            const columnValue = val[columnFilterField];
            const column = columnsAll.find((col) => col.filterField === columnFilterField)
            if (column) {
                if (columnValue === "") {
                    filterInterceptor.removeFilter(column.filterField || "");
                    if (typeof column.id === 'number') {
                        updateImageFilterState(column.id, false);
                    }
                } else {

                    if (modal) {
                        const textFilterConfig: FilterConfig = {
                            name: columnFilterField,
                            typeField: column?.type,
                            label: column.header,
                            value: columnValue,
                            options: column.options,
                            updateFunc: setNewFilterValue,
                        };
                        filterInterceptor.addFilter(textFilterConfig);
                        if (typeof column.id === 'number') {
                            updateFilterState(column.id, false);
                            updateImageFilterState(column.id, true);
                        }
                    }
                }
                filterInterceptor.updateFilterValue(columnFilterField, columnValue);
                setFilterConfig(val)
            }
        }
    }
    const ColumnNamesMapping: Array<ListProductItemConfig> = columnsAll.map(
        (col: ColumnConfig, index: number) => {
            col.id = index;
            // console.log("columns[",index,"]:",columnsAll[index].width)
            return {
                content: col.header === "ACTIONS"
                    ? col.header : (

                        <FilterSortHeader
                            headerText={col.header}
                            filterName={col.filterField || ""}
                            onFilterClick={() => handleFilterClick(col, true)}
                            onSortUpClick={handleSortUpClick}
                            onSortDownClick={handleSortDownClick}
                            isFilterActive={isFilterInputVisible[index]}
                            isFilterOpen={isFilterOpen[index]}
                            setFilterModalClose={() => handleFilterClick(col, false)}
                            setNewFilterValue={setNewFilterValue}
                            fieldType={col.type}
                            options={col.options}
                        />
                    ),
                width: columnsAll[index].width || "auto",
                isLink: col.isLink || false,
                style: col.style || {},
            };
        }
    );
    const createNewUser = (item: any) => {
        if (item.dealerships.length === 0 && item.name !== 'Administrators') {
            toast({
                position: "top",
                title: 'it is impossible to add a user because the company does not have a single dealership',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        modalService.setModal({
            selModel: SelectModal.ADD_USER,
            selCommonContent: item,
            selId: -1
        });
    }

    const updateUser = (item: any, id: number) => {
        modalService.setModal({
            selModel: SelectModal.ADD_USER,
            selCommonContent: item,
            selId: id
        });
    }
    const inviteCreated = (item: any) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                userController.inviteCreate(item.id).then((res) => {
                    toast({
                        position: "top",
                        title: 'Invite ,credentials send to email: ' + item.email,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    setRefreshClients(prevIndex => !prevIndex);
                })
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Are you sure you went to invite send to email:" + item.email,
            context: "action"
        });
    };

    const confirmRegistered = (item: any) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                if (item.defaultUser) {
                    toast({
                        position: "top",
                        title: 'Password reset is not possible because this is the default user',
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                    })
                    return;
                }
                userController.companyRegistered(item.id).then((res) => {
                    toast({
                        position: "top",
                        title: (item.isRegistered === 0 && !item.defaultUser ? "Company registered" : "Reset password") + ',credentials send to email: ' + item.email,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    setRefreshClients(prevIndex => !prevIndex);
                })
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Are you sure " + (item.isRegistered === 0 && !item.defaultUser ? "you went to Approve company" : "reset password") + " and send credentials to email:" + item.email,
            context: "action"
        });
    };

    const changeState = (item: any, state: string) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                if (item) {
                    userController.dealershipFeeState({
                        id: item.id,
                        dealerFee: state,
                        state: state === 'TAX' ? !item.noTax : !item.noShippingFee
                    }).then((res) => {
                        setRefreshClients(prevIndex => !prevIndex);
                    })
                }
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Do you sure that want to change status item " + item.id + "?",
            context: "action"
        });
    };

    const List = listAll?.companies ? listAll.companies
            .map((elem: Company, idx: number) => {
                    const CompanyItemConfig: Array<ListProductItemConfig> = [
                        {
                            content: <Box {...BoxRowStyleConfig} >
                                {(elem.users.length > 0) && <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: '600'
                                    }}>
                                    <Box mr={"0.2rem"}><FaAngleDown/></Box>
                                </div>}
                                <Text><span>{elem.name}{' ('}{' u:'}{elem.users.length}{' d:'}{elem.dealerships.length}{')'}</span></Text>
                            </Box>,
                            width: "85%"
                        },
                        !elem.defaultCompany && {
                            content: <IconWithTooltip icon={FiUserPlus}
                                                      tooltipMessage={'Add New User'}/>,
                            clickAction: () => createNewUser(elem)
                        },
                        !elem.defaultCompany && {
                            content: <IconWithTooltip icon={hasPermission(clientEditPermission) ? FiEdit : FiEye}
                                                      tooltipMessage={hasPermission(clientEditPermission) ? 'Edit' : 'Details'}/>,
                            clickAction: () =>
                                // navigate(`${hasPermission(clientEditPermission) ? URLPaths.COMPANY_EDIT.link : URLPaths.COMPANY_VIEW.link}${elem.name}`
                                navigate(`${hasPermission(clientEditPermission) ? URLPaths.COMPANY_EDIT.link : URLPaths.COMPANY_VIEW.link}${elem.id}`
                                )
                        },
                        hasPermission(clientRemovePermission) && !elem.defaultCompany && {
                            content: <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove'}/>,
                            clickAction: () => handleCompanyRemove(elem)
                        },
                    ].filter(Boolean) as ListProductItemConfig[];

                    const ExtensionUserItemConfig = (elem: User, idx: Key, company: Company) => {
                        let oneDealership = (elem.dealerships && elem.dealerships.length === 1)
                        return ([
                                {
                                    content: <Box {...BoxRowStyleConfig} >
                                        {(elem.dealerships.length > 1) && <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: '600',
                                                marginLeft: "2rem"
                                            }}>
                                            <Box mr={"0.2rem"}><FaAngleDown/></Box>
                                        </div>}
                                        <Text fontWeight={"600"} ml={(elem.dealerships.length > 1) ? 'unset' : '2rem'}>
                                            <span>{'#' + (Number(idx) + 1)}</span>
                                            <span>{' ('}{'d:'}{elem.dealerships.length}{')'}</span>
                                        </Text>
                                    </Box>
                                    , width: "18%"
                                },
                                {
                                    content: elem.login,
                                    // content: !(elem?.dealerships?.length > 1) ? elem.login : (
                                    //     <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    //         <Box mr={"0.2rem"}><FaAngleDown/></Box>
                                    //         <span>{elem.login}</span>
                                    //     </div>
                                    // ),
                                    isLink: false,
                                    width: columnsAll.find((col) => col.filterField === 'userName')?.width || "auto",
                                },
                                {
                                    content: (<Flex flexDir={"column"}>
                                        {elem.email && elem.email.split(',').map(email => (
                                            <span key={email}
                                                  style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}> {email}
                            </span>
                                        ))}
                                    </Flex>),
                                    isLink: false,
                                    // width: "65%",
                                    width: oneDealership ? columnsAll.find((col) => col.filterField === 'userEmail')?.width : "65%",
                                },
                                oneDealership && {
                                    content: elem.dealerships[0].name,
                                    isLink: false,
                                    width: columnsAll.find((col) => col.filterField === 'dealershipName')?.width || "auto",
                                },
                                oneDealership && {
                                    content: (<Flex flexDir={"column"}>
                                        {elem.dealerships[0].email && elem.dealerships[0].email.split(',').map(email => (
                                            <span key={email}
                                                  style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}> {email}
                                                            </span>
                                        ))}
                                    </Flex>),
                                    isLink: false,
                                    width: columnsAll.find((col) => col.filterField === 'dealershipEmail')?.width || "auto",
                                },
                                oneDealership && {
                                    content: <IconWithTooltip icon={FiPercent}
                                                              color={!elem.dealerships[0].noTax ? "black" : "red"}
                                                              tooltipMessage={'Tax ' + (!elem.dealerships[0].noTax ? 'On' : 'Off')}/>,
                                    clickAction: () => changeState(elem.dealerships[0], "TAX")
                                },
                                oneDealership && {
                                    content: <IconWithTooltip icon={FiTruck}
                                                              color={!elem.dealerships[0].noShippingFee ? "black" : "red"}
                                                              tooltipMessage={'Shipping ' + (!elem.dealerships[0].noShippingFee ? 'On' : 'Off')}/>,
                                    clickAction: () => changeState(elem.dealerships[0], "SHIPPING")
                                },
                                {
                                    content: <IconWithTooltip icon={FiUserCheck}
                                                              tooltipMessage={'last login ' + elem.lastLogin}/>
                                },
                                {
                                    content: <IconWithTooltip
                                        icon={elem.isRegistered === 0 && !elem.defaultUser ? FiCheck : FiRotateCcw}
                                        isOutlined={elem.isRegistered === 0 && !elem.defaultUser}
                                        tooltipMessage={elem.isRegistered === 0 && !elem.defaultUser ? 'Accept Registered' : `Reset Password(${(elem?.isRegistered && elem?.isRegistered > 1) ? elem?.isRegistered - 1 : 0})`}/>,
                                    clickAction: () => confirmRegistered(elem)
                                },
                                {
                                    content: <IconWithTooltip
                                        icon={FiItalic}
                                        tooltipMessage={`Invited(${elem?.countInvites})`}/>,
                                    clickAction: () => inviteCreated(elem)
                                },
                                (((elem.isRegistered && elem.isRegistered > 0)
                                        || elem.defaultUser) && hasPermission(clientRemovePermission)
                                ) && {
                                    content: <IconWithTooltip
                                        icon={FiKey}
                                        tooltipMessage={`Set Password(${elem?.isRegistered})`}/>,
                                    clickAction: () => modalService.setModal({
                                        selModel: SelectModal.SET_PASSWORD,
                                        selCommonContent: elem.id
                                    })

                                },
                                {
                                    content: <IconWithTooltip icon={FiBriefcase}
                                                              color={elem.countOrder ? "blue" : "black"}
                                                              tooltipMessage={'Orders count ' + (elem.countOrder)
                                                              }/>,
                                    clickAction: () => navigate(`${(oneDealership ? URLPaths.ORDER_HISTORY.link : URLPaths.ORDER_HISTORY_USER.link)}/${(oneDealership ? elem.dealerships[0].id : elem.id)}`)
                                },
                                {
                                    content: <IconWithTooltip icon={hasPermission(clientEditPermission) ? FiEdit : FiEye}
                                                              tooltipMessage={hasPermission(clientEditPermission) ? 'Edit' : 'Details'}/>,
                                    clickAction: () => updateUser(company, Number(elem.id))
                                },
                                oneDealership && {
                                    content: <IconWithTooltip icon={FiEye}
                                                              tooltipMessage={'Dealership Details'}/>,
                                    clickAction: () => navigate(`${URLPaths.COMPANY_VIEW.link}${company?.id}/${elem.dealerships[0].id}`)
                                },
                                hasPermission(clientRemovePermission) && {
                                    content: <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove'}/>,
                                    clickAction: () => handleRemove(elem)
                                },
                        ].filter(Boolean) as ListProductItemConfig[]
                        );
                    }
                    const ExtensionDealerItemConfig = (elem: Dealership, idxd: Key, userId: number) => {
                        return ([
                                {content: <Text ml={"4rem"}>{'#' + (Number(idxd) + 1)}</Text>, width: "50%"},
                                {content: elem.name, width: "20%"},
                                {
                                    content: (<Flex flexDir={"column"}>
                                        {elem.email && elem.email.split(',').map(email => (
                                            <span key={email}
                                                  style={{wordBreak: 'break-all', overflowWrap: 'break-word'}}> {email}
                            </span>
                                        ))}
                                    </Flex>),
                                    isLink: false,
                                    width: "15%",
                                },
                                {
                                    content: <IconWithTooltip icon={FiPercent} color={!elem.noTax ? "black" : "red"}
                                                              tooltipMessage={'Tax ' + (!elem.noTax ? 'On' : 'Off')}/>,
                                    clickAction: () => changeState(elem, "TAX")
                                },
                                {
                                    content: <IconWithTooltip icon={FiTruck} color={!elem.noShippingFee ? "black" : "red"}
                                                              tooltipMessage={'Shipping ' + (!elem.noShippingFee ? 'On' : 'Off')}/>,
                                    clickAction: () => changeState(elem, "SHIPPING")
                                },
                                {
                                    content: <IconWithTooltip icon={FiEye}
                                                              tooltipMessage={'Dealership Details'}/>,
                                    clickAction: () => navigate(`${URLPaths.COMPANY_VIEW.link}${elem?.companyId}/${elem.id}`)
                                },
                                {
                                    content: <IconWithTooltip icon={FiBriefcase} color={elem.countOrder ? "blue" : "black"}
                                                              tooltipMessage={'Orders count ' + elem.countOrder}/>,
                                    clickAction: () => navigate(`${URLPaths.ORDER_HISTORY.link}/${elem.id}`)
                                },
                                {
                                    content: <AddedProductVendor dealership={elem} userId={userId}/>,
                                },
                            ].filter(Boolean)
                        );
                    }
                    return <ListProductItemRow key={idx} list={CompanyItemConfig}
                                               clickAction={() => handleRowCompanyClick(Number(elem.id))}
                                               style={{
                                                   fontSize: "13px", minHeight: "35px",
                                                   justifyContent: "flex-start",
                                                   cursor: (elem?.users?.length > 0) ? "pointer" : "auto",
                                                   backgroundColor: (elem?.users?.length > 0 && elem?.users.some(user => user.isRegistered === 0 && !user.defaultUser)) ? 'lightpink' : 'unset'
                                               }}>
                        {(expandedCompaniesRows.includes(Number(elem.id)) && (elem?.users?.length > 0)) && (
                            elem.users?.map((user: User, idxU: Key) => {
                                return (
                                    <ListProductItemRow key={idxU} list={ExtensionUserItemConfig(user, idxU, elem)}
                                                        clickAction={() => handleRowUsersClick(Number(user.id))}
                                                        style={{
                                                            fontSize: "13px", minHeight: "35px",
                                                            justifyContent: "flex-start",
                                                            cursor: (elem?.users?.length > 0) ? "pointer" : "auto",
                                                            bgColor: user.isRegistered === 0 && !user.defaultUser ? 'lightpink' : 'white'
                                                        }}>
                                        {
                                            (expandedUsersRows.includes(Number(user.id)) && (user?.dealerships?.length > 1)) && (
                                                user.dealerships?.map((dealer: Dealership, idxD: Key) => {
                                                    return (
                                                        <ListProductItemRow
                                                            key={idxD}
                                                            list={ExtensionDealerItemConfig(dealer, idxD, Number(user.id))}
                                                            style={{
                                                                background: "white",
                                                                justifyContent: "flex-start",
                                                                bgColor: 'white',
                                                                fontSize: "13px", minHeight: "30px"
                                                            }}
                                                        />
                                                    )
                                                })
                                            )
                                        }
                                    </ListProductItemRow>
                                )
                            })
                        )
                        }
                    </ListProductItemRow>
                }
            ) :
        null;

    return (
        <>
            <Breadcrumbs/>
            <Flex flexDir="column" height="100%">
                <Flex flexDir="row" mb="10px" justifyContent="space-between" alignItems="end">
                    <Flex
                        flexDir="row"
                        alignItems="end"
                        width="80%"
                        fontFamily="Poppins"
                        position="relative"
                        flexWrap="wrap">
                        {filterInterceptor.getFiltersRowWithCreateButton()}
                    </Flex>
                    <Flex fontSize={14} ml={"auto"} pt={1}>
                        <AllowedAccess permissionKey={subscribeExcludeViewPermission}>
                            <Flex>
                                <NavBarLinkButton
                                    text={'MCE'}
                                    onClick={() => navigate(URLPaths.SUBSCRIBE_EXCLUDE.link)}/>
                                <Box backgroundColor={"gray.200"} width="1px"/>
                            </Flex>
                        </AllowedAccess>
                        <AllowedAccess permissionKey={clientCreatePermission}>
                            <Flex pl={1}>
                                <NavBarLinkButton text={'Add Company'}
                                                  onClick={() => navigate(URLPaths.COMPANY_CREATE.link)}/>
                            </Flex>
                        </AllowedAccess>
                    </Flex>
                </Flex>
                {
                    isLoading
                        ? <Loader/>
                        : <>
                            <ListProductItemRow list={ColumnNamesMapping} style={{
                                fontSize: "13px",
                                fontWeight: "700",
                                backgroundColor: "aliceblue",
                                justifyContent: "flex-start"
                            }}/>
                            {List}
                        </>
                }
            </Flex>
            <AddUserModal onRefresh={handleRefreshComponent}/>
            <Paginator limit={typeof listAll?.count === 'number' ? listAll?.count : 0}
                       current={currentPaginatorPosition}
                       swapPageTrigger={handlePageChange} step={20}/>
            <SetPasswordModal/>
        </>
    )
}
export default Clients;