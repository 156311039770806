import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {AuthorizationRepositoryInstance} from "../../repository/Authorization/AuthorizationRepository";
import {AuthorizationData} from "../../shared/entities/Auth/AuthorizationData";
import {Option} from "../../shared/utilities/OptionT";
import {AuthTypes} from "../../redux/Auth/AuthReducer";

export class AuthorizationService {
    private dispatch: Dispatch<PayloadAction<any, string>>
    private repository = AuthorizationRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any, string>>) {
        this.dispatch = dispatch
    }

    public async authorize(data: AuthorizationData): Promise<Option<any>> {
        return this.repository.authorize(data).then(res => {
            this.dispatch({type: AuthTypes.GET_AUTH, payload: res?.Some})
            return res
        })
    }

    public async getPublicKey(): Promise<Option<any>> {
        return this.repository.getPublicKey().then(res => {
            this.dispatch({type: AuthTypes.PUBLIC_KEY, payload: res?.Some})
            return res
        })
    }

    public async checkAuth(): Promise<Option<any>> {
        return this.repository.checkAuh().then(res => {
            this.dispatch({type: AuthTypes.GET_AUTH, payload: res?.Some})
            return res
        })
    }

    public async refresh() {
        return this.repository.refresh().then(res => res)
    }

    public async logOut() {
        return this.repository.logOut().then(res => res)
    }
}


