import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {Order} from "../../shared/entities/Order/Order";
import {CheckoutRequest, CheckoutRequestNewUser} from "../../shared/entities/Client/CheckoutRequest";
import {ORDER_API_PREFIX} from "../../common/constants";

/**
 * ProductRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to ClientService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class OrderRepository {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async getOrder(id: number, unregistered: boolean, email?: string): Promise<Option<Order>> {
        let reqUrl = `${id}`;
        let param = {};
        if (unregistered) {
            reqUrl = `unregistered/${id}`
            param = {email}
        }
        return await this.dao.getRequest<Order>(`${ORDER_API_PREFIX}/${reqUrl}`, {params: {...param}}).then(res => {
            const result: Option<Order> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<Order> = {
                Some: {} as Order,
                None: err
            }
            return result
        })
    }

    public async orderCheckout(data: CheckoutRequest): Promise<Option<Order>> {
        return await this.dao.postRequest<Order>(`${ORDER_API_PREFIX}/checkout`, {data}).then(res => {
            const result: Option<Order> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<Order> = {
                Some: {} as Order,
                None: err
            }
            return result
        })
    }

    public async createOrderNewUser(data: CheckoutRequestNewUser): Promise<Option<Order>> {
        return await this.dao.postRequest<Order>(`${ORDER_API_PREFIX}/checkout-new-user`, {data}).then(res => {
            const result: Option<Order> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<Order> = {
                Some: {} as Order,
                None: err
            }
            return result
        })
    }

    public async getOrderList(data: any): Promise<Option<{ orders: Array<Order>, count: number }>> {
        return await this.dao.postRequest<{
            orders: Array<Order>,
            count: number
        }>(`${ORDER_API_PREFIX}/all`, data).then(res => {
            const result: Option<{ orders: Array<Order>, count: number }> = {
                Some: {
                    orders: res?.data?.orders,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<{ orders: Array<Order>, count: number }> = {
                //@ts-ignore
                Some: {
                    orders: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }

    public async invoicePdf(ids: Array<number>): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${ORDER_API_PREFIX}/invoice-combine/pdf`, {
            ...{data: ids},
            responseType: "blob"
        }).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async packingSleep(ids: Array<number>): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${ORDER_API_PREFIX}/packing-sleep/pdf`, {
            ...{data: ids},
            responseType: "blob"
        }).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async sendInvoice(ids: Array<number>): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${ORDER_API_PREFIX}/invoice/send`, {data: ids}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async printPressed(ids: Array<number>): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`${ORDER_API_PREFIX}/invoice/print-pressed`, {data: ids}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async removeOrder(id: number): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`${ORDER_API_PREFIX}/remove`, {params: {id}}).then(res => {
            const result: Option<boolean> = {
                Some: true,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<boolean> = {
                Some: false,
                None: err
            }
            return result
        })
    }

    public async orderConfirmState(data: any): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`${ORDER_API_PREFIX}/confirm-state`, {data}).then(res => {
            const result: Option<boolean> = {
                Some: true,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<boolean> = {
                Some: false,
                None: err
            }
            return result
        })
    }
}

export const OrderRepositoryInstance = new OrderRepository(APIDao)
