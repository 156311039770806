import { combineReducers, configureStore, PayloadAction, Store } from "@reduxjs/toolkit";
import { UserReducer, UserState } from "./Users/UserReducer";
import { ModalReducer, ModalState } from "./Modal/ModalReducer";
import thunk from "redux-thunk";
import { AuthState, AuthReducer } from "./Auth/AuthReducer";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { BreadcrumbsState, BreadcrumbsReducer } from "./Breadcrumbs/BreadcrumbsReducer";
import { RoleReducer, RoleState } from "./Role/RoleReducer";
import { ProductState,ProductReducer } from "./Product/ProductReducer";
import {CartReducer, CartState} from "./Cart/CartReducer";
import {OrderReducer,OrderState} from "./Order/OrderReducer";
import {OrderProductReducer, OrderProductState} from "./OrderProduct/OrderProductReducer";
import {StatesReducer, StatesState} from "./State/StateReducer";
import {NavBarReducer, NavBarState} from "./NavBar/NavBarReducer";
import {DragAndDropReducer, DragAndDropState} from "./CurrentState/DragAndDropReducer";
import {SubscribeReducer, SubscribeState} from "./Mail/SubscribeReducer";
import ImageReducer, {ImageState} from "./Image/ImageReducer";

const reducer = combineReducers({
    RoleReducer,
    UserReducer,
    ModalReducer,
    AuthReducer,
    BreadcrumbsReducer,
    ProductReducer,
    OrderReducer,
    OrderProductReducer,
    CartReducer,
    StatesReducer,
    NavBarReducer,
    DragAndDropReducer,
    SubscribeReducer,
    ImageReducer
})

type AppState = {
    RoleReducer: RoleState;
    UserReducer: UserState
    ModalReducer: ModalState
    AuthReducer: AuthState
    BreadcrumbsReducer: BreadcrumbsState
    ProductReducer: ProductState
    OrderReducer: OrderState
    OrderProductReducer: OrderProductState
    StatesReducer: StatesState
    CartReducer: CartState
    NavBarReducer: NavBarState
    DragAndDropReducer:DragAndDropState
    SubscribeReducer:SubscribeState
    ImageReducer:ImageState
}

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export const store: Store<any, PayloadAction<any>> = configureStore({
    reducer,
    middleware: [thunk],
    devTools: true
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
