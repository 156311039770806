import React from 'react';
import {Flex, Text} from '@chakra-ui/react';
import {Product} from '../../../shared/entities/Product/Product';
import EditButton from "../../../components/Buttons/EditButton";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import {CardBody} from "../../../components/Card/CardBody";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import CustomLink from "../../../components/Link/CustomLink";
import AllowedAccess, {hasPermission} from "../../../features/Permission/AllowedAccess";
import {productEditPermission} from "../../permissions";

interface ProductItemProps {
    product: Product;
    height?: string;
    width?: string;
    isMobile?: boolean;
}

const ProductGeneralItem: React.FC<ProductItemProps> = ({
                                                            product,
                                                            height = "100%",
                                                            width = "100%",
                                                            isMobile = false
                                                        }) => {
    return (
        <Flex w={width} height={height}>
            <AllowedAccess permissionKey={productEditPermission} localPermission={!isMobile}>
                <DragAndDropBox item={product} width={"5%"} orderByZone={true}/>
            </AllowedAccess>
            <CardBody>
                <DragAndDropBox item={product} isDraggable={hasPermission(productEditPermission)}>
                    <EditButton item={product}/>
                    <ImageCustom elem={product} currentSize={"subgeneral"} sizeBackend={"GENERAL"}
                                 isMobile={isMobile}
                                 sizeInPercent={100}/>
                    <CustomLink fontWeight={"md"} fontSize={22} lineHeight={"115%"}
                                href={WebPUtils.getProductLink(product)}>
                        <Flex px={6} cursor={"pointer"}
                              alignItems="center"
                              justifyContent="flex-start"
                              width="100%" height={24}>
                            <Text fontWeight={"md"} fontSize={22} lineHeight={"115%"} wordBreak="break-word"
                                  whiteSpace={"pre-wrap"}>
                                {product.name}
                            </Text>
                        </Flex>
                    </CustomLink>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};
export default ProductGeneralItem;
