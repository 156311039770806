import InputMask from 'react-input-mask';
import {FormControl, FormErrorMessage, Input, InputGroup, InputLeftAddon} from "@chakra-ui/react";
import {FieldInputProps, FormikProps, useField} from 'formik';
import React from 'react';

interface ClientMaskedInputProps {
    field: FieldInputProps<any>;
    form: FormikProps<any>;

    [name: string]: any;
}

export const ClientMaskedInput: React.FC<ClientMaskedInputProps> = ({field, form, ...props}) => {
    const [, meta] = useField(field.name);
    return (
        <FormControl isInvalid={meta.touched && !!meta.error}>
            <InputMask {...field} {...props}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           const value = e.target.value.replace(/[^0-9]/g, ''); // Убираем все, кроме цифр
                           form.setFieldValue(field.name, value);
                       }}
                       onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                           // Блокируем ввод всех символов, кроме цифр
                           if (!/[0-9]/.test(e.key)) {
                               e.preventDefault();
                           }
                       }}
            >
                {() => (
                    <InputGroup>
                        <InputLeftAddon>+1</InputLeftAddon>
                        <Input {...props} />
                    </InputGroup>
                )}
            </InputMask>
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    );
};